@import 'src/assets/sass/vars.scss';
@import 'src/assets/sass/mixins';
@import 'src/assets/sass/animations';

.papers{
    margin-bottom: 144px;
    .team__title{
        margin-bottom: 48px;
    }
    &__info{
        width: 100%;
        max-width: 100%;
    }
}
.papers-item{
    border-radius: 2px;
    background: var(--Supper-Light, #F5F6F8);
    padding: 39px 53px 44px 32px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    height: 488px;
}
.papers-header{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    &__text{
        max-width: 548px;
        font-family: 'Inter', sans-serif;
        font-size: 27px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        color: #000;
    }
    &__image{
        min-width: 62px;
        width: 62px;
        height: 55px;
        img{
            width: 100%;
            height: 100%;
        }
    }
}
.papers-main{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    &__image{
        width: 72px;
        height: 72px;
        border-radius: 50%;
        img{
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }
}
.papers-autor{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 8px;
    &__name{
        color: #000;
        font-family: Inter;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 147.5%;
    }
    &__light{
        color: var(--fgh, #7F7E82);
        font-family: Inter;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 147.5%;
    }
    &__original{
        margin: 0;
        width: 227px;
        height: 66px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        border-radius: 2px;
        border-image: linear-gradient(#FA3D33, #EE8B52) 30;
        border-width: 1px;
        border-style: solid;
        color: var(--Dark, #0A0D14);
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 130.5%;
        letter-spacing: 0.84px;
        -webkit-text-decoration-line: underline;
                text-decoration-line: underline;
        text-transform: uppercase;
        -webkit-transition: all .5s ease;
        -o-transition: all .5s ease;
        transition: all .5s ease;
        &:hover{
            background: #FA3D33 -o-radial-gradient(8.97% 0%, 91.03% 223.07%, #FA3D33 2.73%, #EE8B52 100%);
            background: #FA3D33 radial-gradient(91.03% 223.07% at 8.97% 0%, #FA3D33 2.73%, #EE8B52 100%);
            color: #fff;
        }
    }
}
.all-papers{
    color: var(--fill-1, #FA3D33);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 130.5%;
    letter-spacing: 0.84px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    text-transform: uppercase;
}
.papers-list{
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 20px 1fr;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin-top: 109px;
    margin-bottom: 144px;
}
@media(max-width:768px){
    .papers-item{
        padding: 16px;
        height: auto;
        gap: 63px;
    }
    .papers-header{
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
            -ms-flex-direction: column-reverse;
                flex-direction: column-reverse;
        gap: 24px;
        &__text{
            font-family: 'Inter', sans-serif;
            font-size: 17px;
            font-weight: 400;
            line-height: 175%;
        }
    }
    .papers-main{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;
        gap: 63px;
    }
    .papers-autor__original{
        width: 100%;
    }
    .all-papers{
        position: absolute;
        bottom: 22px;
    }
    .papers-list{
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        margin-top: 48px;
        margin-bottom: 0;
    }
}