@import '../../../../../assets/sass/vars';
@import '../../../../../assets/sass/mixins';

.main-articles {
  padding-bottom: 13.75rem;

  &__wrapper {
    width: 97%;
    margin: 0 0 0 3%;
  }

  &__slider {
    margin-top: 4.0625rem;
    padding-bottom: 6.875rem;

    .swiper-container {
      padding-bottom: 6.25rem;
    }

    .swiper-button-prev {
      @extend %arrow;
      bottom: 10px;
      top: auto;
      right: calc(3% + 5.5rem);
      left: auto;
    }

    .swiper-button-next {
      @extend %arrow;
      bottom: 10px;
      top: auto;
      right: 3%;
      left: auto;
    }
  }

  &__slide {
    width: 36.25rem;
    overflow: hidden;

    &-pic {
      height: 25rem;
      width: 100%;
      object-fit: cover;
      object-position: center;
      margin-bottom: 15px;
      border-radius: 4px;
    }

    &-title {
      font-size: 1.125rem;
      line-height: 1.875rem;
    }
  }

  &__showAll {
    @extend %showAllBtn;
    position: absolute;
    bottom: 20px;
    left: 0;
  }

  &__showMore {
    @extend %showMoreBtn;
  }
}

@media (max-width: 1024px) {
  .main-articles__slide {
    width: 530px;
  }

  .main-articles__slide-pic {
    height: 360px;
    margin-bottom: 0;
  }

  .main-articles__slide-title {
    font-size: 18px;
    line-height: 30px;
    font-weight: bold;
    margin-top: 15px;
    display: inline-block;
  }

  .main-articles__title-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-right: 3%;
  }

  .main-articles__showAll_mobile {
    font-size: 14px;
    line-height: 130.5%;
    text-align: right;
    text-decoration-line: underline;
  }

  .main-articles__slider {
    padding-bottom: 0;
  }

  .main-articles {
    padding-bottom: 110px;
  }

  .main-articles__slider .swiper-container {
    padding-bottom: 100px;
  }

  .main-articles__showAll {
  }

  .main-articles__slider .swiper-button-next {
    right: 1.5%;
    left: auto !important;
  }

  .main-articles__slider .swiper-button-prev {
    right: 95px;
    left: auto !important;
  }
}

@media (max-width: 560px) {
  .main-articles__wrapper {
    width: 91%;
    margin: 0 auto;
  }

  .main-articles__title-container {
    margin-bottom: 55px;
  }

  .main-articles__slide {
    width: 100%;
    margin-bottom: 45px;
  }

  .main-articles__slide-pic {
    height: 300px;
  }
  .main-articles__slider .swiper-button-next {
    right: 25px;
  }

  .main-articles__slider .swiper-button-prev {
    right: 105px;
  }
}

@media (max-width: 475px) {
  .main-articles__slider {
    margin-top: 45px;
  }

  .main-articles__slide-pic {
    height: 260px;
  }

  .main-articles__slide-title {
    font-size: 14px;
    line-height: 163%;
  }
}

@media (max-width: 420px) {
  .main-articles__slide-pic {
    height: 240px;
  }
}

@media (max-width: 375px) {
  .main-articles__slide-pic {
    height: 210px;
  }
}

