.asap-live{
    margin-bottom: 75px;
    &__wrapper{
        display: grid;
        grid-template-columns: 2fr 1fr 3fr;
    }
    &-item{
        &:nth-child(2){
            padding-top: 20px;
        }
        &:first-child{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 32px;
           .asap-live-item-header{
                display: flex;
                align-items: center;
                gap: 11px;
                color: var(--text-main-900, #0A0D14);
                font-family: 'Inter', sans-serif;
                font-size: 36px;
                font-style: normal;
                font-weight: 500;
                line-height: 48px;
                letter-spacing: -0.72px;
                svg{
                    min-width: 18px;
                    margin-top: 7px;
                }
            }
        }
        &:last-child{
            display: flex;
            flex-direction: column;
            gap: 50px;
            justify-content: space-between;
        }
    }
}

.vc-list{
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.line-vc-item{
    padding-bottom: 24px;
    border-bottom: 1px solid var(--stroke-sub-300, #CDD0D5);
    display: flex;
    flex-direction: column;
    gap: 16px;
    &__header{
        display: flex;
        gap: 10px;
        align-items: center;
        .label-text{
            color: #868C98;
        }
    }
    &__icon{
        border-radius: 100px;
        background: var(--bg-soft-200, #E2E4E9);
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 26px;
        }
    }
    .body-xl{
        color: #0A0D14;
    }
}

.asap-line-footer{
    display: flex;
    flex-direction: column;
    gap: 25px;
    &-item{
        display: flex;
        align-items: center;
        gap: 10px;
        img{
            border-radius: 100%;
            width: 40px;
            height: 40px;
        }
        &__textbox{
            display: flex;
            flex-direction: column;
            gap: 8px;
            p{
                color: #0A0D14;
            }
            span{
                color: #0A0D14;
                text-decoration: underline;
            }
        }
    }
    &-box{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

@media(max-width: 1024px){
    .asap-live{
        &__wrapper{
            grid-template-columns: 1fr;
        }
        &-item{
            &-header{
                max-width: 140px;
                gap: 39px;
            }
            &:nth-child(2){
                padding: 0;
                margin-top: 30px;
                margin-bottom: 30px;
            }
            &:last-child{

            }
        }
        
        .vc-list{
            gap: 50px;
            p.body-xl{
                font-size: 21px;
                font-weight: 400;
                line-height: 27px;
                letter-spacing: -0.315px;
            }
        }
    }
    .asap-line{
        &-footer{
            gap: 35px;
            &-box{
                grid-template-columns: 1fr;
                gap: 35px;
            }
        }
    }
}
