@import '../../../assets/sass/vars';
@import '../../../assets/sass/mixins';

.menu-container {
  &_mobile {
    .mobile-menu {
      visibility: visible;
      opacity: 1;

      &__list {
        width: 94%;
      }
    }

    .menu:before {
      opacity: 0;
    }

    .menu:not(.menu_fixed) {
      background: $gray !important;

    }

    .menu_fixed {
      background: transparent;
    }

    .menu-start:after, .menu .burger:after {
      display: none;
    }
  }

  .menu:not(.menu_fixed) {
    .menu-logotitle {
      color: $text;
    }

    .mobile-menu {
      color: $text;
    }

    .mobile-menu:after {
      background: #fff;
    }


    &:before {
      background: #fff;
    }

    .menu-item {
      color: $text;
    }

    .menu:before {
      background: $text;
    }

    .menu-start {
      color: $text;
    }

    .menu-start:after {
      content: none;
    }

    .menu-item_cabinet svg path {
      stroke: $accent;
    }

    .menu-item_cabinet:hover {
      svg path {
        fill: $accent;
      }
    }
  }

  &_secondary {
    .menu:not(.menu_fixed) .menu-link {
      color: #fff;
    }

    .menu:not(.menu_fixed) {
      background: transparent;

      .menu-start:after, .menu .burger:after {
        background: $text;
      }
    }
    .menu.header-white .menu-blur{
      background: rgba(255, 255, 255, 0.62);
      // background: #fff;
    }

    .menu:not(.menu_fixed) .menu-link:hover:after {
      background: $accentGrad;
    }

    .menu:not(.menu_fixed) .menu-link:hover {
      @include textGrad();
    }

    .menu:not(.menu_fixed):before {
      background: $text;
    }

    .menu:not(.menu_fixed) .menu-start:after,
    .menu:not(.menu_fixed) .burger:after {
      background: $text;
    }

    .menu-start, .menu .burger {
      color: $text;
    }
  }
}

.menu {
  color: #0A0D14;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 75px;
  left: 0;
  z-index: 21;
  position: fixed;
  //top: -100%;
  //left: 0;
  //padding: 0 3%;
  @include animatedHorizontalBorder(0, auto);
  @include swipeLeft;
  //transition: all .4s ease-out;
  .wrapper{
    display: grid;
    grid-template-columns: 1fr 200px;
    gap: 20px;
    z-index: 4;
  }
  &-blur{
    position: fixed;
    top: 0;
    height: 75px;
    width: 100%;
    left: 0;
    z-index: 2;
    background: rgba(57, 57, 57, 0.42);
    backdrop-filter: blur(21px);
  // filter: blur(36.900001525878906px);
  }

  &.mounted * {
    &[data-aos^=fade][data-aos^=fade] {
      opacity: 1 !important;
      transform: none !important;
    }

    &[data-aos] {
      pointer-events: auto;
    }
  }

  &.mounted {
    &[data-aos="swipe-left"]:before {
      width: 100%;
      opacity: 1;
    }

    &[data-aos="swipe-down"] {
      &:after {
        height: 100% !important;
        opacity: 1;
      }
    }
  }

  &_visible {
    top: 0;
  }

  &_fixed {
    background: radial-gradient(91.03% 223.07% at 8.97% 0%, #FA3D33 2.73%, #EE8B52 100%);
    width: 100%;
    left: 0;
    padding: 0 3%;
  }

  &:before {
    content: none;
  }

  &:after {
    content: none;
  }

  &-links {
    width: 85%;
    display: grid;
    grid-template-columns: minmax(150px, 220px) 1fr;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  }

  &-link {
    color: #fff;
    position: relative;
    font-size: 16px;
    white-space: nowrap;
    &.active {
      @include textGrad();

      &:after {
        width: 100%;
        background: $accentGrad;
      }
    }

    &:after {
      position: absolute;
      bottom: -2px;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      background-color: #fff;
      content: "";
      transform: scale(0);
      transition: transform .3s cubic-bezier(0.11, 0.7, 0, 1);
    }
    
    &:hover:after {
      transform: scale(1);
    }
   
  }

  &-start, .burger {
    position: relative;
    width: 15%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    @include animatedVerticalBorder(0, auto);
    @include swipeDown;

    &:after {
      background: #fff;
      left: 0;
      bottom: 0;
    }
  }

  &-title {
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 220px;

  }

  &-logo {
    width: 6.25rem;
    display: flex;
    align-items: center;
    object-fit: contain;
  }

  &-logotitle {
    font-weight: 500;
    font-size: 1.125rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  &-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    width: initial;
    height: 100%;
    gap: 0;
    max-width: 720px;
  }

  &-item {
    width: auto;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    &_cabinet {
      padding: 0 4.375rem 0 1.875rem;

      svg path {
        transition: fill .4s ease-out;
      }

      &:hover {
        svg path {
          fill: #fff;
        }
      }
    }
    &:first-child{
      .menu-link{
        height: 100%;
        display: flex;
        align-items: center;
        background: var(--Gradient, linear-gradient(85deg, #FA3D33 -1.61%, #DC824F 98.48%));
        width: 261px;
        justify-content: center;
        &::after{content: none}
        &:hover{
          color: #fff !important;
          background: var(--Gradient, linear-gradient(85deg, #FA3D33 -1.61%, #DC824F 98.48%)) !important;
          text-decoration: none;
        }
      }
    }
    
  }
  &-start{
    justify-content: flex-end;
    width: fit-content;
  }
  &-right{
    display: flex;
    gap: 50px;
  }
}
.menu-container_secondary .menu:not(.menu_fixed) .menu-link[href="/consultation"]:hover{
  color: #fff !important;
}
.burger__icon {
  position: relative;
  width: 30px;
  height: 30px;

  &:before, &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: $text;
  }

  &:before {
    top: calc(50% - 1px);
    left: 0;
    transform: rotate(45deg);
    animation: burgerFirstLine .3s ease forwards;
  }

  &:after {
    top: calc(50% - 1px);
    left: 0;
    transform: rotate(-45deg);
    animation: burgerSecondLine .3s ease forwards;
  }
}

@media (max-width: 1200px) {
  .menu-list {
    width: 100%;
    gap: 10px;
  }
}

@media (max-width: 1024px) {
  .menu-list {
    display: none;
  }

  .menu-container_mobile {
    .burger {
      justify-content: flex-end;
    }
  }
}

@media (max-width: 670px) {
  .menu {
    margin-top: 30px;
    height: 55px;
  }

  .menu_visible {
    height: 55px;
  }
  .menu-container_mobile .menu_fixed {
    height: 5.625rem;
  }

  .menu-logo {
    width: 70px;
  }
}

@media (max-width: 560px) {
  .menu {
    width: 91%;
    left: 4.5%;
  }

  .menu-container_mobile .mobile-menu__list {
    width: 91%;
  }
}

@media (max-width: 475px) {
  .menu .burger {
    width: 30%;
    font-size: 14px;
  }
}

@media (max-width: 420px) {
  .menu_visible:not(.menu_fixed) {
    width: 85%;
    left: 7.5%;
  }

  .menu_fixed {
    padding: 0 7.5%;
  }

  .menu {
    width: 85%;
    left: 7.5%;
  }
}

@media (max-width: 375px) {
  .menu-title {
    width: 65%;
  }
  .menu .burger {
    width: 35%;
  }
}


@media(max-width: 1024px){
  .menu .wrapper{
    grid-template-columns: 1fr 44px;
  }
  .menu-links{
    grid-template-columns: 1fr;
    max-width: 100%;
    width: 100%;

  }
  .menu-start, .menu .burger{
    width: 44px;
    &::after{
      content: none;
    }
  }
  .menu{
    margin-top: 0;
    height: 80px;
  }
  .menu-blur{
    height: 80px;
  }
  .menu-container.menu-container_mobile {
    .menu-blur{
      background: none;
      backdrop-filter: none;
    }
    .menu.mounted{
      background: transparent !important;
      .wrapper{
        height: 80px;
      }
      .menu-links{
        height: 80px;
      }
      .menu-title{
        height: 80px;
        svg{
          height: 100%;
        }
      }
    }
  }
  .burger__icon{
    width: 44px;
    height: 44px;
  }
  .burger__icon:before, .burger__icon:after{
    height: 3px;
    background: #fff;
  }
}

.menu-list .menu-item{
  &:first-child{
    a{
      color: #fff !important;
    }
  }
}

.menu-container_secondary .menu.header-white{
  .menu-link{
    color: #0A0D14;
  }
}