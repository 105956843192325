@import 'vars';
@import 'animations';
@import 'mixins';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style: none;
}

html {
  font-size: 16px;
  color: $text;
  background: $gray;

  --portfolioPicHeight: 60vh;
  --teamPicHeight: 22vw;
  --acticleHeight: 20vw;
}

body {
  font-family: $font;
  margin: 0;
  padding: 0;
  cursor: default;
  color: $textGray;
  position: relative;
  background: $gray;
}

section {
  width: 100%;
  position: relative;
  overflow: hidden;
}

a {
  text-decoration: none;
  color: inherit;
  display: inline-block;
  transition: all 0.3s ease;
}

input:not([type='radio']),
textarea,
button,
select,
option {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  font-family: 'Inter', sans-serif;
  outline: none; 
}

input {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

button {
  background: transparent;
  border: none;
  display: inline-block;
  cursor: pointer;
  outline: none;
}

.title {
  font-weight: normal;
  font-size: 2.5625rem;
  line-height: 3.125rem;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  display: none;
}

@include scroll(body, transparent, $accent, 0px, transparent);

.loader {
  position: fixed;
  left: 0;
  bottom: -100vh;
  width: 100%;
  height: 100vh;
  background: $gray;
  z-index: 100;
  transition: bottom 0.5s ease-out;
  will-change: bottom;

  &.active {
    bottom: 0;
  }
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.transition-group {
  position: relative;

  &:after {
    content: '';
    background: red;
    position: absolute;
    bottom: -100vh;
    left: 0;
    width: 100%;
    height: calc(100vh - 6rem);
    z-index: 100;
    transition: all 0.5s ease;
    visibility: hidden;
  }
}

.route-section {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

main {
  &.hidden {
    .header__main-container {
      z-index: -20;
      visibility: hidden;
    }

    .header {
      background: transparent;
    }

    .header__blur {
      visibility: hidden;
    }
  }
}

.noscroll {
  overflow: hidden;
}

.wrapper {
  margin: 0 auto;
  width: 94%;
  position: relative;
  height: 100%;
  // margin: 0 auto;
  // max-width: 1780px;
  // width: calc(100% - 30px);
  // position: relative;
  // height: 100%;
}

.crumbs {
  &__crumb {
    margin-top: 1.5625rem;
    font-size: 1.125rem;
    line-height: 153%;
    text-decoration: underline;
    color: #7f7e82;

    &:before {
      content: '—';
      display: inline-block;
      margin: 0 5px;
    }

    &:first-child:before {
      display: none;
    }

    &_active {
      color: $textGray;
    }
  }
}

@import 'media';
