.nedwiz{
    background: #fff;
}

@media(max-width: 1024px){
    // .nedwiz{
    //     .title-section__text.body-xl{
    //         margin-bottom: 40px;
    //     }
    //     .razrabotka-slider{
    //         margin-bottom: 75px;
    //     }
    // }
}