.expertiza{
    display: flex;
    flex-direction: column;
    gap: 50px;
    &__title{
        color: #0A0D14;
        max-width: 663px;
    }
    &__wrapper{
        display: flex;
        flex-direction: column;
        gap: 50px;
        margin-bottom: 75px;
    }
}

.grid-cards{
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    a{
        width: 100%;
        height: 100%;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(5),
        &:nth-child(6){
            width: calc(25% - 12.5px);
            .projects-gallery__project-pic{
                // height: 307px;
            }
        }
        &:nth-child(3),
        &:nth-child(4){
            width: calc(50% - 25px);
            .projects-gallery__project-pic{
                // height: 560px;
            }
        }
        
        margin: 0;
        display: flex;
        flex-direction: column;
        .projects-gallery__project-pic{
            padding: 0;
            height: 100%;
            img{
                position: initial;
                object-fit: cover;
            }
        }
    }
    .card {
        background-color: #f0f0f0;
        border: 1px solid #ddd;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 20px;
        font-size: 16px;
    }
}

.projects-gallery{
    &__project-info{
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    &__project-title{
        margin: 0;
    }
    &__text{
        margin-bottom: 10px;
    }
}

@media(min-width: 1590px){
    .grid-cards{
        display: flex;
        flex-wrap: wrap;
        gap: 25px;
        a{
            width: 100%;
            height: 100%;
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(5),
            &:nth-child(6){
                width: calc(25% - 12.5px);
                .projects-gallery__project-pic{
                    // height: 400px;
                }
            }
            // &:nth-child(3),
            // &:nth-child(4){
            //     width: calc(50% - 25px);
            //     .projects-gallery__project-pic{
            //         height: 560px;
            //     }
            // }
            
            
        }
    }
    
}
@media(max-width: 1024px){
    .expertiza{
        &__wrapper{
            gap: 40px;
        }
    }
    .grid-cards{
        flex-direction: column;
        gap: 32px;
        a{
            width: 100%;
            &:nth-child(1), &:nth-child(2),&:nth-child(5), &:nth-child(6){
                width: 100%;
            }
            &:nth-child(3), &:nth-child(4){
                width: 100%;
            }
        }
    }
    .projects-gallery__project-pic-wrapper{
        padding: 0;
    }
    .grid-cards a .projects-gallery__project-pic{
        // height: 307px;
    }
    .grid-cards a{
        &:nth-child(3),
        &:nth-child(4){
            .projects-gallery__project-pic{
                // height: 307px;
            }
        }
    }
    .projects-gallery__project .projects-gallery__project-tags{
        height: fit-content;
    }
}