@import '../../../assets/sass/vars';
@import '../../../assets/sass/mixins';
@import '../../../assets/sass/animations';

.purple-title {
  &__title {
    font-size: 18rem;
    line-height: 79%;
    letter-spacing: -0.015em;
    text-transform: uppercase;
    @include textGrad;
    white-space: nowrap;
    display: inline-block;
    margin-top: 6.875rem;
    padding-bottom: 1.875rem;
    margin-right: 5rem;

    &_animated {
      animation: scrollText 20s infinite linear;
    }
  }

  &__title-box {
    position: relative;
    overflow: hidden;
    width: 106%;
    margin-top: 5rem;
    //left: -20px;
    left: -3%;

    &:after {
      //content: '';
      position: absolute;
      bottom: 0;
      z-index: 2;
      left: 0;
      width: 100%;
      height: 20px;
      background: $gray;
    }
  }

  &__title-wrapper {
    z-index: 2;
    position: relative;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    transition: all 0.2s;
    will-change: transform;
    user-select: none;
    padding: 0 1.5%;
    scrollbar-width: none;

    &_safari {
      overflow-x: auto;
      overflow-y: hidden;
    }
  }
}

.purple-title__title-wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.purple-title__title-wrapper {
  -ms-overflow-style: none;
}

.purple-title__title-wrapper {
  overflow: -moz-scrollbars-none;
}

@media (max-width: 1024px) {
  .purple-title__title {
    font-size: 156px;
  }
}

@media (max-width: 780px) {
  .purple-title__title-box {
    margin-top: 50px;
  }

  .purple-title__title {
    padding-bottom: 50px;
    margin-right: 50px;
  }
}

@media (max-width: 670px) {
  .purple-title__title-box {
    margin-top: 150px;
  }
  .purple-title__title {
    font-size: 144px;
    margin-top: 0;
  }
}

@media (max-width: 560px) {
  .purple-title__title-box {
    width: 110%;
    left: -6.5%;
  }

  .purple-title__title {
    padding: 0 4.5%;
    margin-right: 30px;
  }
}

@media (max-width: 420px) {
  .purple-title__title-box {
    width: 116%;
    left: -7.5%;
  }

  .purple-title__title {
    padding: 0 7.5%;
  }
}


