@import "../../../assets/sass/vars";
@import "../../../assets/sass/mixins";

.toTopBtn {
  //animation: fadeUpBtn .5s  ease-out forwards;
  @include scrollBtn(3.75rem, 5.625rem);
  position: fixed;
  right: 5.625rem;
  bottom: 5.625rem;
  background: $textGray;
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 100%;
  box-shadow: 0px 12px 24px rgba(65, 57, 84, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .5s ease-out;
  z-index: 90;

  &:hover {
    box-shadow: 0px 22px 28px rgba(65, 57, 84, 0.4);
  }

  svg {
    width: 50%;
    height: auto;
    transition: all .3s ease;
  }
}

@media (max-width: 1024px) {

  .toTopBtn {
    @include scrollBtn(10px, 30px);
    width: 80px;
    height: 80px;
    right: 30px;
    bottom: 30px;

    svg {
      width: 40px;
    }
  }
}

@media (max-width: 560px) {
  .toTopBtn {
    width: 60px;
    height: 60px;

    svg {
      width: 30px;
    }
  }
}

@media (max-width: 375px) {
  .toTopBtn {
    @include scrollBtn(0px, 15px);
    width: 50px;
    height: 50px;
    right: 15px;
    bottom: 15px;

    svg {
      width: 25px;
    }
  }
}

@media (max-width: 320px) {
  .toTopBtn {
    @include scrollBtn(0px, 15px);
    width: 50px;
    height: 50px;
    right: 15px;
    bottom: 15px;

    svg {
      width: 15px;
    }
  }
}
