.news-section{
    margin-bottom: 75px;
    &__wrapper{
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
    &-grid{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
    }
    &-item{
        display: flex;
        flex-direction: column;
        gap: 10px;
        &__img{
            margin-bottom: 10px;
            height: 100%;
            width: 100%;
            max-height: 462px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 0.5s ease;
            }
            &:hover{
                img{
                    transform: scale(1.05);
                }
            }
        }
        &__date{
            color: var(--text-main-900, #0A0D14);
            font-family: 'Inter', sans-serif;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 42px;
            letter-spacing: -0.13px;
            opacity: 0.7;
        }
        &__text{
            color: var(--text-main-900, #0A0D14);
            font-family: 'Inter', sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 133%;
            letter-spacing: -0.18px;
        }
    }
}
@media(max-width: 768px){
    .news-section{
        &-grid{
            grid-template-columns: 1fr;
            gap: 40px;
        }
    }
    .news-section-item__date{
        line-height: 100%;
    }
}