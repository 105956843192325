@import '../../../assets/sass/vars';
@import '../../../assets/sass/mixins';
@import '../../../assets/sass/animations';

.projects{background: #fff;}
.industries {
  padding-top: 1.875rem;
  padding-bottom: 4.375rem;

  &__title {
    color: $text;
    font-weight: bold;
    font-size: 1.75rem;
    line-height: 2.5rem;
    margin-bottom: 1.875rem;
  }

  &__button {
    @extend %industries__button;
    margin-right: 1.25rem;
  }

  &__select {
    overflow: hidden;
    height: 60px;
    min-height: 60px;
    border: 1px solid #CECECE;
    box-sizing: border-box;
    margin-bottom: 40px;
    z-index: 5;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: absolute;

    &-list {
      visibility: hidden;
    }

    &_opened {
      height: auto;

      .industries__select-list {
        visibility: visible;
      }

      .industries__select-cur:after {
        transform: rotate(-225deg);
        margin-bottom: -5px;
      }
    }

    &-cur {
      box-sizing: border-box;
      height: 60px;
      position: absolute;
      font-size: 14px;
      line-height: 20px;
      margin: 0 23px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(100% - 50px);

      &:after {
        content: '';
        width: 10px;
        height: 10px;
        border-left: 2px solid #CECECE;
        border-bottom: 2px solid #CECECE;
        transform: rotate(-45deg);
        margin-bottom: 5px;
        transition: all .3s ease;
      }
    }

    &-item {
      display: inline-block;
      height: 60px;
      width: 100%;
      background: $gray;
      border-top: 1px solid #CECECE;

      &_active {
        display: none;
      }
    }
  }
}

.list {
  &__title {
    -webkit-text-stroke: 1.2px $accent;
    color: transparent;
    font-size: 6rem;
    font-weight: 700;
    line-height: 6.25rem;
    letter-spacing: 0.04em;
    text-align: left;
  }

  &__box {
    margin-top: 2.125rem;
    display: flex;
    flex-direction: column;
  }
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #0A0D1442;
  padding: 1rem 0;
  transition: border-color .3s;

  &:hover {
    border-color: $accent;

    .item__name {
      color: $accent;
    }
  }

  &__name {
    width: 30%;
    color: $text;
    margin-left: 4px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.625rem;
    transition: color .3s;
  }

  &__link {
    width: 14%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.75rem;
    color: $text;
    cursor: pointer;
  }

  &__tags {
    display: flex;
    justify-content: flex-start;
    margin-right: 2rem;
  }

  &__tag {
    @extend %tag;
    display: flex;
    align-items: center;
    margin-right: 0.875rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.projects-list {
  overflow-x: hidden;
  overflow-y: visible;
  height: auto;
  background: #fff;

  .wrapper {
    //padding-top: 11.875rem;
    padding-bottom: 6.875rem;
  }

  .industries__buttons {
    margin-bottom: 4.0625rem;
  }

  &__list {
    margin-bottom: 4.6875rem;
  }

  &__title {
    font-weight: bold;
    font-size: 6rem;
    line-height: 101%;
    @include textGrad;
  }

  &__tabs {
    display: flex;
    position: relative;
    @include animatedHorizontalBorder(0, auto);
    @include swipeLeft;
    padding-bottom: 0.9375rem;
    margin-top: 5.625rem;
  }

  &__tab {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 125%;
    padding-right: 3.4375rem;
    color: #828282;
    letter-spacing: -0.01em;
    transition: all .3s ease;

    &:last-child {
      padding-right: 0;
    }

    &.active {
      font-weight: bold;
      @include textGrad;
    }
  }

  &__table {
    display: flex;
    flex-direction: column;
  }

  &__year {
    font-weight: 400;
    font-size: 3rem;
    line-height: 3.125rem;
    color: $textGray;
    margin-bottom: 15px;
    display: inline-block;
  }

  &__row {
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;
    align-items: center;
    @include animatedHorizontalBorder(0, auto);
    @include swipeLeft;
    padding: 0.9375rem 0 0.55rem;

    &:hover {
      &:before {
        background: $accentGrad;
      }

      .projects-list__item-title {
        @include textGrad();
      }
    }

    &-title {
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 1.875rem;
      width: 35%;
      color: $textGray;
      text-align: left;
    }

    &-link {
      font-size: 1.125rem;
      line-height: 125%;
      letter-spacing: -0.01em;
      display: flex;
      color: $textGray;
      align-items: center;
      justify-content: flex-end;
      width: 210px;

      svg {
        margin-right: 4px;
      }
    }

    &-tags, &-types {
      display: flex;
      align-items: center;
      width: 25%;
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    &-tag, &-type {
      color: #0A0D14;
      text-align: left;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.14px;
      margin-right: 0.9375rem;
      margin-bottom: 15px;
      padding: 3px 9px;
      background: #F6F8FA;
      margin-left: 0.625rem;
      margin-bottom: 0.725rem;

      &:last-child {
        margin-right: 0;
      }
    }
   
  }

  &__showMore {
    @extend %showMoreBtn;
    width: 17.5rem;
  }
}



@media (max-width: 1200px) {
  .projects-list__item-title {
    width: 37%;
  }

  .projects-list__item-tags {
    width: 35%;
  }
}

@media (max-width: 1024px) {
  .industries {
    padding-bottom: 45px;
  }
  .projects-gallery .projects-gallery__project{
    margin-bottom: 48px;
  }
  .projects-list__item-title {
    width: 85%;
  }

  .projects-list__item-link {
    color: #0A0D14;
    text-decoration: underline;
  }

  .projects-list__item-link:before {
    order: 2;
    margin-left: 15px;
    margin-right: 0;
    top: 0;
  }
}

@media (max-width: 780px) {
  .projects-list__item-title {
    width: 70%;
  }
  .projects-list__item{
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-bottom: 15px;
  }
  .projects-list__item-title{
    width: 100%;
    margin-bottom: 10px;
  }
  .projects-list__item-tags{
    width: initial;
    gap: 0.875rem;
  }
  .projects-list__item-tag, 
  .projects-list__item-type{
    margin-top: 0;
    margin-left: 0;
  }
  .projects-list__item-link{
    width: 100%;
    text-align: left;
    justify-content: flex-start;
  }
 
}

@media (max-width: 670px) {
  .projects-list__year {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #FA3D33;
    margin-bottom: 10px;
  }
  .projects-list__item{
    padding: 16px 0;
  }
  .projects-list__item-title {
    font-weight: 400;
    font-size: 18px;
  }

  .projects-list__item-link {
    font-size: 17px;
  }

  .industries__buttons {
    margin-bottom: 50px;
  }
}

@media (max-width: 560px) {
  .projects-list__showMore {
    width: 100%;
  }

  .industries__buttons {
    padding-top: 60px;
  }

  .industries__select-list {
    width: 100%;
    position: relative;
    margin-top: 60px;
    max-height: 360px;
    overflow: scroll;
    @include scroll('.industries__select-list', transparent, $accent, 0px, transparent);
  }

  .industries__select-cur:before {
    content: '';
    height: 1px;
    background: #CECECE;
    position: absolute;
    bottom: 0;
    left: -23px;
    width: calc(100% + 50px);
  }

  .projects-list {
    min-height: auto;
  }

  .industries {
    padding-bottom: 100px;
  }

  .projects-list__list {
    margin-bottom: 64px;
  }
  .projects-gallery__project-info{
    padding-top: 16px;
    margin-bottom: 0;
  }
  .projects-gallery__project .projects-gallery__project-tags{
    margin-top: 0;
  }
}

@media (max-width: 475px) {
  .projects-list__year {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
  }
  .projects-list__item-title {
    font-size: 14px;
    line-height: 24px;
  }

  .projects-list__item {
    padding: 15px 0 17px;
  }
  .projects-list__item-link {
    font-size: 14px;
    line-height: 18px;
  }

  .projects-list__item-link:before {
    width: 15px;
    height: 15px;
    margin-left: 13px;
  }

  .projects-list__list {
    margin-bottom: 64px;
  }

  .projects-list__showMore {
    margin-top: 40px;
  }

  .projects-list .wrapper {
    padding-bottom: 120px;
  }
  .projects-gallery__project .projects-gallery__project-tags{
    margin-top: 0;
  }
  .projects-gallery__project:last-child{
    margin-bottom: 0;
  }
  .projects-gallery .projects-gallery__project{
    display: block;
  }
}
