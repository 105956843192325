@import '../../../assets/sass/vars';
@import '../../../assets/sass/mixins';

.topic {
  overflow: visible;
  &__box {
    display: flex;
  }

  &__aside {
    width: 35%;
    height: fit-content;
    position: sticky;
    top: 8rem;
    margin-top: 2rem;
  }

  &__btn {
    display: block;
    margin-bottom: 1.875rem;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #7f7e82;
    font-size: 0.8125rem;
    font-weight: 500;
    position: relative;
    &::after {
      content: '';
      top: 100%;
      left: 0;
      width: 100%;
      position: absolute;
      height: 1px;
      background: transparent;
    }
    &.active {
      @include textGrad();
      &::after {
        background: radial-gradient(91.03% 223.07% at 8.97% 0%, #FA3D33 2.73%, #EE8B52 100%);
      }
    }
  }

  &__wrap {
    width: 65%;
  }

  &__item {
    margin-bottom: 12.5rem;
  }

  &__title {
    @include textGrad();
    font-size: 2.9375rem;
    margin-bottom: 2.125rem;
  }
  &__text {
    width: 37.5rem;
    max-width: 100%;
    font-size: 0.875rem;
    color: $textGray;
    line-height: 1.4;
    margin-bottom: 0.5rem;
  }
  &__techs {
    margin-bottom: 4.375rem;
    &Icon {
      width: 3.125rem;
      margin-right: 1.25rem;
      height: 3.125rem;
      object-fit: contain;
      box-sizing: border-box;
      padding: 0.625rem;
      border-radius: 50%;
      background-color: #c4c4c4;
    }
  }
  &__gallery {
    display: flex;
    margin-bottom: 5rem;
    &Link {
      background-color: #dddddd;
      border-radius: 1px;
      overflow: hidden;
      width: 30%;
      height: 15rem;
      &:not(:last-of-type) {
        margin-right: 5%;
      }
    }
    &Img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__more {
    display: inline-block;
    margin-bottom: 1.875rem;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 600;
    position: relative;
    @include textGrad();
    &::after {
      content: '';
      top: 100%;
      left: 0;
      width: 100%;
      position: absolute;
      height: 1px;
      background: radial-gradient(91.03% 223.07% at 8.97% 0%, #FA3D33 2.73%, #EE8B52 100%);
    }
  }
}
.price {
  margin-top: 4rem;
  &__item {
    display: flex;
    justify-content: space-between;
    color: $textGray;
    padding-bottom: 1.25rem;
    border-bottom: 1px solid $textGray;
    margin-bottom: 2.5rem;
  }
  &__value,
  &__title {
    font-size: 1.4375rem;
    font-weight: 600;
  }

  &__link {
    cursor: pointer;

    transition: all 0.3s ease;
    &:hover {
      background: radial-gradient(91.03% 223.07% at 8.97% 0%, #FA3D33 2.73%, #EE8B52 100%);
      -webkit-background-clip: text;
      color: transparent;
      -webkit-text-stroke-width: 0;
    }
  }
  &__params {
    margin-top: 0.75rem;
    opacity: 0.7;
  }
}

//END OF NEW SCSS FOR LIST
.services {
  height: auto;
  min-height: 80rem;

  .wrapper {
    padding-bottom: 20vh;
  }

  .orbit__circle {
    height: 80vh;
    right: auto;
    left: 3%;
    width: 80%;
    top: 16.375rem;
  }

  .orbit__ellipse {
    width: 45rem;
    height: 45rem;
  }

  .orbit__dot-content {
    display: flex;
    flex-direction: column !important;
  }

  .orbit__dot-num {
    font-weight: 500;
  }

  #dot1 {
    left: 86%;
    top: 81%;

    .orbit__dot-content {
      top: -4rem;
      left: 4rem;
    }
  }

  #dot2 {
    left: 0;
    top: 63%;

    .orbit__dot-text {
      margin-left: 0;
      margin-top: -5px;
    }

    .orbit__dot-content {
      top: -5rem;
      left: -8rem;
      width: 12.5rem;
    }
  }

  .purple-title__title-box {
    z-index: 3;
  }

  .purple-title__title-box:after {
    display: none;
  }

  .purple-title__title-wrapper {
    overflow: hidden;
    cursor: default;
  }

  &__box {
    display: flex;
    justify-content: space-between;
  }

  &__info {
    width: 60%;
    z-index: 3;
  }

  &__title {
    width: 150%;
    position: relative;
    z-index: 1;
    margin-top: 4.125rem;
    text-transform: uppercase;
    font-size: 18rem;
    font-style: normal;
    font-weight: 400;
    line-height: 14.25rem;
    letter-spacing: -0.015em;
    background: radial-gradient(91.03% 223.07% at 8.97% 0%, #FA3D33 2.73%, #EE8B52 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__text {
    width: 57%;
    color: $text;
    margin-top: 4.375rem;
    margin-left: 8.5rem;
    font-size: 1.75rem;
    font-weight: 400;
    line-height: 2.375rem;
  }

  &__img {
    position: absolute;
    top: 1.875rem;
    right: 0;
    height: calc(100vh - (1.875rem + 5rem));
    object-fit: cover;
    width: 45%;
  }
}

@media (max-width: 1600px) {
  .services {
    height: 76.875rem;
    min-height: auto;
  }

  .services .orbit__ellipse {
    width: 40rem;
    height: 40rem;
  }

  .services .orbit__circle {
    top: 20rem;
  }

  .services__img {
    max-height: 800px;
  }
}

@media (max-width: 1350px) {
  .services {
    height: 1000px;
    min-height: auto;
  }
}

@media (max-width: 1200px) {
  .topic {
    &__aside {
      display: none;
    }
    &__wrap {
      margin-top: 3.75rem;
      width: 100%;
    }
    &__title {
      font-size: 1.8125rem;
    }
    &__text {
      margin-bottom: 1.125rem;
      font-size: 0.8125rem;
    }
    &__item {
      margin-bottom: 3.75rem;
    }
  }
  .price {
    &__item {
      display: block;
    }
    &__info {
      margin-bottom: 1.25rem;
    }
    &__value,
    &__title {
      font-size: 1.125rem;
    }
    &__params {
      font-size: 0.8125rem;
    }
  }
  .services__img {
    max-height: 700px;
  }

  .services #dot2 {
    left: -2%;
    top: 55%;
  }

  .services #dot1 {
    left: 90%;
    top: 74%;
  }
}

@media (max-width: 1024px) {
  .services {
    height: auto;

    .purple-title__title-box {
      z-index: 1;
    }

    .purple-title__title-box:after {
      display: block;
    }

    .purple-title__title-wrapper {
      overflow-x: scroll;
      overflow-y: hidden;
    }

    .facts-table__item:nth-child(odd) {
      align-items: flex-start;
    }

    .facts-table__item:nth-child(even) {
      align-items: flex-end;
    }
  }

  .services .wrapper {
    padding-bottom: 0;
  }

  .services__box {
    flex-direction: column;
  }

  .services__info {
    width: 100%;
    margin-bottom: 500px;
  }

  .services__text {
    font-size: 28px;
    line-height: 40px;
    margin-left: 0;
    margin-top: 30px;
    margin-bottom: 50px;
    width: 90%;
  }

  .services__img {
    position: relative;
    left: -4%;
    max-height: 530px;
    object-position: top;
    width: 108%;
  }

  .services .orbit__circle {
    height: 800px;
    top: 350px;
    right: auto;
    left: 15%;
    width: 80%;
  }

  .services .orbit__ellipse {
    width: 65vw;
    height: 65vw;
  }

  .services .orbit__dot-num {
    font-size: 8vw !important;
  }

  .services #dot2 .orbit__dot-content {
    top: -210px;
    left: -45px;
  }

  .services #dot1 .orbit__dot-content {
    left: -50px;
    top: -220px;
  }

  .services .orbit__dot-text {
    font-size: 18px;
    line-height: 24px;
  }

  .services #dot2 .orbit__dot-text,
  .structure #dot1 .orbit__dot-text {
    top: 130px;
    left: -150px;
    margin-left: 0;
  }

  .services #dot1 .orbit__dot-text {
    margin-top: 0;
    left: -140px;
  }

  .services #dot1 {
    top: 70%;
    left: auto;
    right: 3%;
  }

  .services #dot2 {
    top: 50%;
    left: -10px;
  }
}

@media (max-width: 780px) {
  .services .orbit__dot-text {
    font-size: 16px;
    line-height: 22px;
  }

  .services .orbit__dot-num {
    font-size: 62px !important;
  }

  .services #dot2 .orbit__dot-text,
  .structure #dot1 .orbit__dot-text {
    top: 115px;
    left: -130px;
    margin-left: 0;
  }

  .services #dot2 .orbit__dot-content {
    top: -190px;
    left: -45px;
  }

  .services #dot1 .orbit__dot-content {
    top: -170px;
  }

  .services__info {
    margin-bottom: 350px;
  }

  .services .orbit__dot-text {
    font-size: 14px;
  }
}

@media (max-width: 670px) {
  .services .facts-table {
    flex-direction: column-reverse;
  }

  .services .facts-table__item:nth-child(even) {
    align-items: flex-start;

    .facts-table__item-content {
      width: 100%;
    }
  }

  .facts-table__item:nth-child(even) .facts-table__item-content {
    justify-content: flex-start;
  }

  .facts-table__item:nth-child(odd) .facts-table__item-content {
    justify-content: flex-end;
  }

  .services .facts-table__item:nth-child(odd) {
    align-items: flex-end;
  }

  .services .facts-table__item:nth-child(1) {
    .facts-table__item-content {
      display: flex;
      flex-direction: column;
      //align-items: flex-end;
    }

    .facts-table__item-text {
      width: 70%;
      margin-left: 0;
    }

    .facts-table__item-num {
      font-size: 84px;
    }
  }

  .services__info {
    margin-bottom: 0;
  }

  .services .facts-table__item:nth-child(even) .facts-table__item-content {
    justify-content: flex-end;
  }

  .services .facts-table__item-text {
    width: 40%;
  }
}

@media (max-width: 560px) {
  .projects .purple-title__title {
    padding: 0 2.5%;
  }

  .services__img {
    left: -5%;
    max-height: 550px;
    width: 110%;
  }

  .services__text {
    font-size: 18px;
  }

  .services__info {
    margin-top: 50px;
  }

  .services .facts-table__item:nth-child(1) .facts-table__item-num {
    font-size: 64px;
  }

  .services__text {
    margin-top: 0;
  }

  .services .facts-table__item:nth-child(2) .facts-table__item-num {
    font-size: 96px;
  }
}

@media (max-width: 475px) {
  .services__text {
    font-size: 14px;
    line-height: 24px;
    width: 100%;
  }
}

@media (max-width: 420px) {
  .services__img {
    left: -9%;
    max-height: 520px;
    width: 120%;
  }

  .services .facts-table__item:nth-child(1) .facts-table__item-num {
    font-size: 64px;
  }

  .services .facts-table__item:nth-child(1) .facts-table__item-content {
    align-items: flex-start;
  }

  .services .facts-table__item:nth-child(1) .facts-table__item-text {
    margin-left: 0;
  }

  .services .facts-table__item:nth-child(2) .facts-table__item-num {
    font-size: 84px;
  }
}

@media (max-width: 375px) {
}
@import '../../../assets/sass/vars';
@import '../../../assets/sass/mixins';

//START NEW SCSS FOR LIST

.topic {
  overflow: visible;
  &__box {
    display: flex;
  }

  &__aside {
    width: 35%;
    height: fit-content;
    position: sticky;
    top: 8rem;
    margin-top: 2rem;
  }

  &__btn {
    display: block;
    margin-bottom: 1.875rem;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #7f7e82;
    font-size: 0.8125rem;
    font-weight: 500;
    position: relative;
    &::after {
      content: '';
      top: 100%;
      left: 0;
      width: 100%;
      position: absolute;
      height: 1px;
      background: transparent;
    }
    &.active {
      @include textGrad();
      &::after {
        background: radial-gradient(91.03% 223.07% at 8.97% 0%, #FA3D33 2.73%, #EE8B52 100%);
      }
    }
  }

  &__wrap {
    width: 65%;
  }

  &__item {
    margin-bottom: 12.5rem;
  }

  &__title {
    @include textGrad();
    font-size: 2.9375rem;
    margin-bottom: 2.125rem;
  }
  &__text {
    width: 37.5rem;
    max-width: 100%;
    font-size: 0.875rem;
    color: $textGray;
    line-height: 1.4;
    margin-bottom: 0.5rem;
  }
  &__techs {
    margin-bottom: 4.375rem;
    &Icon {
      width: 3.125rem;
      margin-right: 1.25rem;
      height: 3.125rem;
      object-fit: contain;
      box-sizing: border-box;
      padding: 0.625rem;
      border-radius: 50%;
      background-color: #c4c4c4;
    }
  }
  &__gallery {
    display: flex;
    margin-bottom: 5rem;
    &Link {
      background-color: #dddddd;
      border-radius: 1px;
      overflow: hidden;
      width: 30%;
      height: 15rem;
      &:not(:last-of-type) {
        margin-right: 5%;
      }
    }
    &Img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__more {
    display: inline-block;
    margin-bottom: 1.875rem;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 600;
    position: relative;
    @include textGrad();
    &::after {
      content: '';
      top: 100%;
      left: 0;
      width: 100%;
      position: absolute;
      height: 1px;
      background: radial-gradient(91.03% 223.07% at 8.97% 0%, #FA3D33 2.73%, #EE8B52 100%);
    }
  }
}
.price {
  &__item {
    display: flex;
    justify-content: space-between;
    color: $textGray;
    padding-bottom: 1.25rem;
    border-bottom: 1px solid $textGray;
    margin-bottom: 2.5rem;
  }
  &__value,
  &__title {
    font-size: 1.4375rem;
    font-weight: 600;
  }
  &__params {
    margin-top: 0.75rem;
    opacity: 0.7;
  }
}

//END OF NEW SCSS FOR LIST
.services {
  height: auto;
  min-height: 80rem;

  .wrapper {
    padding-bottom: 20vh;
  }

  .orbit__circle {
    height: 80vh;
    right: auto;
    left: 3%;
    width: 80%;
    top: 16.375rem;
  }

  .orbit__ellipse {
    width: 45rem;
    height: 45rem;
  }

  .orbit__dot-content {
    display: flex;
    flex-direction: column !important;
  }

  .orbit__dot-num {
    font-weight: 500;
  }

  #dot1 {
    left: 86%;
    top: 81%;

    .orbit__dot-content {
      top: -4rem;
      left: 4rem;
    }
  }

  #dot2 {
    left: 0;
    top: 63%;

    .orbit__dot-text {
      margin-left: 0;
      margin-top: -5px;
    }

    .orbit__dot-content {
      top: -5rem;
      left: 3rem;
      width: 20rem;
    }
  }

  .purple-title__title-box {
    z-index: 3;
  }

  .purple-title__title-box:after {
    display: none;
  }

  .purple-title__title-wrapper {
    overflow: hidden;
    cursor: default;
  }

  &__box {
    display: flex;
    justify-content: space-between;
  }

  &__info {
    width: 60%;
    z-index: 3;
  }

  &__title {
    width: 150%;
    position: relative;
    z-index: 1;
    margin-top: 4.125rem;
    text-transform: uppercase;
    font-size: 18rem;
    font-style: normal;
    font-weight: 400;
    line-height: 14.25rem;
    letter-spacing: -0.015em;
    background: radial-gradient(91.03% 223.07% at 8.97% 0%, #FA3D33 2.73%, #EE8B52 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__text {
    width: 57%;
    color: $text;
    margin-top: 4.375rem;
    margin-left: 8.5rem;
    font-size: 1.75rem;
    font-weight: 400;
    line-height: 2.375rem;
  }

  &__img {
    position: absolute;
    top: 1.875rem;
    right: 0;
    height: auto;
    max-height: calc(100vh - (1.875rem + 5rem));
    object-fit: cover;
    width: 45%;
  }
}

@media (max-width: 1600px) {
  .services {
    height: 76.875rem;
    min-height: auto;
  }

  .services .orbit__ellipse {
    width: 40rem;
    height: 40rem;
  }

  .services .orbit__circle {
    top: 20rem;
  }

  .services__img {
    height: 800px;
  }
}

@media (max-width: 1350px) {
  .services {
    height: 1000px;
    min-height: auto;
  }
}

@media (max-width: 1200px) {
  .topic {
    &__aside {
      display: none;
    }
    &__wrap {
      margin-top: 3.75rem;
      width: 100%;
    }
    &__title {
      font-size: 1.8125rem;
    }
    &__text {
      margin-bottom: 1.125rem;
      font-size: 0.8125rem;
    }
    &__item {
      margin-bottom: 3.75rem;
    }
  }
  .price {
    &__item {
      display: block;
    }
    &__info {
      margin-bottom: 1.25rem;
    }
    &__value,
    &__title {
      font-size: 1.125rem;
    }
    &__params {
      font-size: 0.8125rem;
    }
  }
  .services__img {
    height: 700px;
  }

  .services #dot2 {
    left: -2%;
    top: 55%;
  }

  .services #dot1 {
    left: 90%;
    top: 74%;
  }
}

@media (max-width: 1024px) {
  .services {
    height: auto;

    .purple-title__title-box {
      z-index: 1;
    }

    .purple-title__title-box:after {
      display: block;
    }

    .purple-title__title-wrapper {
      overflow-x: scroll;
      overflow-y: hidden;
    }

    .facts-table__item:nth-child(odd) {
      align-items: flex-start;
    }

    .facts-table__item:nth-child(even) {
      align-items: flex-end;
    }
  }

  .services .wrapper {
    padding-bottom: 0;
  }

  .services__box {
    flex-direction: column;
  }

  .services__info {
    width: 100%;
    margin-bottom: 500px;
  }

  .services__text {
    font-size: 28px;
    line-height: 40px;
    margin-left: 0;
    margin-top: 30px;
    margin-bottom: 50px;
    width: 90%;
  }

  .services__img {
    position: relative;
    left: -4%;
    max-height: 530px;
    object-position: top;
    width: 108%;
  }

  .services .orbit__circle {
    height: 800px;
    top: 350px;
    right: auto;
    left: 15%;
    width: 80%;
  }

  .services .orbit__ellipse {
    width: 65vw;
    height: 65vw;
  }

  .services .orbit__dot-num {
    font-size: 8vw !important;
  }

  .services #dot2 .orbit__dot-content {
    top: -210px;
    left: -45px;
  }

  .services #dot1 .orbit__dot-content {
    left: -50px;
    top: -220px;
  }

  .services .orbit__dot-text {
    font-size: 18px;
    line-height: 24px;
  }

  .services #dot2 .orbit__dot-text,
  .structure #dot1 .orbit__dot-text {
    top: 130px;
    left: -150px;
    margin-left: 0;
  }

  .services #dot1 .orbit__dot-text {
    margin-top: 0;
    left: -140px;
  }

  .services #dot1 {
    top: 70%;
    left: auto;
    right: 3%;
  }

  .services #dot2 {
    top: 50%;
    left: -10px;
  }
}

@media (max-width: 780px) {
  .services .orbit__dot-text {
    font-size: 16px;
    line-height: 22px;
  }

  .services .orbit__dot-num {
    font-size: 62px !important;
  }

  .services #dot2 .orbit__dot-text,
  .structure #dot1 .orbit__dot-text {
    top: 115px;
    left: -130px;
    margin-left: 0;
  }

  .services #dot2 .orbit__dot-content {
    top: -190px;
    left: -45px;
  }

  .services #dot1 .orbit__dot-content {
    top: -170px;
  }

  .services__info {
    margin-bottom: 350px;
  }

  .services .orbit__dot-text {
    font-size: 14px;
  }
}

@media (max-width: 670px) {
  .services .facts-table {
    flex-direction: column-reverse;
  }

  .services .facts-table__item:nth-child(even) {
    align-items: flex-start;

    .facts-table__item-content {
      width: 100%;
    }
  }

  .facts-table__item:nth-child(even) .facts-table__item-content {
    justify-content: flex-start;
  }

  .facts-table__item:nth-child(odd) .facts-table__item-content {
    justify-content: flex-end;
  }

  .services .facts-table__item:nth-child(odd) {
    align-items: flex-end;
  }

  .services .facts-table__item:nth-child(1) {
    .facts-table__item-content {
      display: flex;
      flex-direction: column;
      //align-items: flex-end;
    }

    .facts-table__item-text {
      width: 70%;
      margin-left: 0;
    }

    .facts-table__item-num {
      font-size: 84px;
    }
  }

  .services__info {
    margin-bottom: 0;
  }

  .services .facts-table__item:nth-child(even) .facts-table__item-content {
    justify-content: flex-end;
  }

  .services .facts-table__item-text {
    width: 40%;
  }
}

@media (max-width: 560px) {
  .projects .purple-title__title {
    padding: 0 2.5%;
  }

  .services__img {
    left: -5%;
    max-height: 550px;
    width: 110%;
  }

  .services__text {
    font-size: 18px;
  }

  .services__info {
    margin-top: 50px;
  }

  .services .facts-table__item:nth-child(1) .facts-table__item-num {
    font-size: 64px;
  }

  .services__text {
    margin-top: 0;
  }

  .services .facts-table__item:nth-child(2) .facts-table__item-num {
    font-size: 96px;
  }
}

@media (max-width: 475px) {
  .services__text {
    font-size: 14px;
    line-height: 24px;
    width: 100%;
  }
}

@media (max-width: 420px) {
  .services__img {
    left: -9%;
    max-height: 520px;
    width: 120%;
  }

  .services .facts-table__item:nth-child(1) .facts-table__item-num {
    font-size: 64px;
  }

  .services .facts-table__item:nth-child(1) .facts-table__item-content {
    align-items: flex-start;
  }

  .services .facts-table__item:nth-child(1) .facts-table__item-text {
    margin-left: 0;
  }

  .services .facts-table__item:nth-child(2) .facts-table__item-num {
    font-size: 84px;
  }
}

.services{
  background: #fff;
}