@import 'mixins';
@import 'animations';
@import 'vars';

@media (max-width: 1600px) {
  html {
    font-size: 16px;
  }
}

@media (max-width: 1550px) {
  html {
    font-size: 15.5px;
  }
}

@media (max-width: 1500px) {
  html {
    font-size: 15px;
  }
}

@media (max-width: 1450px) {
  html {
    font-size: 14.5px;
  }
}

@media (max-width: 1400px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 1350px) {
  html {
    font-size: 13.5px;
  }
}

@media (max-width: 1300px) {
  html {
    font-size: 13px;
  }
}

@media (max-width: 1250px) {
  html {
    font-size: 12.5px;
  }
}

@media (max-width: 1200px) {
  html {
    font-size: 12px;
  }
}

@media (max-width: 1024px) {
  html {
    font-size: 12px;
  }

  .title {
    font-size: 46px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 45px !important;
    height: 45px !important;
  }

  .swiper-button-prev {
    left: 0 !important;
  }

  .swiper-button-next {
    left: 55px !important;
  }

  .crumbs__crumb {
    font-size: 18px;
    line-height: 153%;
  }
}

@media (max-width: 560px) {
  .wrapper {
    width: 91%;
  }

  section .title {
    font-size: 38px;
  }

  html:not(.no-js) [data-aos^='fade'][data-aos^='fade']:not(.purple-title__title_animated) {
    // opacity: 1 !important;
    // transform: none !important;
  }

  body * {
    &[data-aos] {
      pointer-events: auto;
    }

    &[data-aos='swipe-down'] {
      &:after {
        height: 100% !important;
        opacity: 1;
      }
    }

    &[data-aos='swipe-left'] {
      &:before {
        width: 100%;
        opacity: 1;
      }
    }
  }
}

@media (max-width: 475px) {
  section .title {
    font-size: 24px;
    line-height: 130.5%;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 35px !important;
    height: 35px !important;
  }
}

// @media (max-width: 420px) {
//   .wrapper {
//     width: 85%;
//   }
// }
