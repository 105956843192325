@import "vars";
@import "animations";

@mixin hover {
  & {
    transition: all .4s ease-out;
  }
  &:hover {
    transform: scale(1.05);
  }
}

@mixin flexbox() {
  display: flex;
  align-items: center;
  align-self: center;
}

@mixin textGrad() {
  background: linear-gradient(85deg, #FA3D33 -1.61%, #DC824F 98.48%);
  -webkit-background-clip: text;
  color: transparent !important;
  -webkit-text-stroke-width: 0;
}

@mixin animatedVerticalBorder($left, $right, $width:1px, $color: $text) {
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: $right;
    width: $width;
    height: 0;
    background: $color;
    transition: all .7s ease-out .2s;
  }
}

@mixin animatedHorizontalBorder($bottom, $top, $height:1px, $color: $text) {
  &:before {
    content: '';
    position: absolute;
    top: $top;
    bottom: $bottom;
    left: 0;
    width: 0;
    height: $height;
    background: $color;
    transition: all .7s ease-out;
  }
}

@mixin scrollBtn($bottomStart, $bottomEnd) {
  & {
    @keyframes fadeUpBtn {
      from {
        bottom: $bottomStart;
        opacity: 0;
      }

      to {
        bottom: $bottomEnd;
        opacity: 1;
      }
    }
    animation: fadeUpBtn .5s  ease-out forwards;
  }

}

@mixin scroll($child, $backgroundColor, $scrollColor, $borderRadius, $borderColor: white) {

  #{$child}::-webkit-scrollbar {
    width: .5%;
    min-width: 8px;
  }

  #{$child}::-webkit-scrollbar-track {
    background: $backgroundColor;
  }

  #{$child}::-webkit-scrollbar-thumb {
    border-left: 1px solid $borderColor;
    background: $scrollColor;
    border-radius: $borderRadius;
  }
}

@mixin swipeLeft {
  &[data-aos="swipe-left"].aos-animate {
    &:before {
      animation: swipeLeft .6s ease-out forwards;
    }
  }

  @media (max-width: 560px)  {
    &[data-aos="swipe-left"].aos-animate {
      &:before {
        animation: none;
      }
    }
  }
}

@mixin swipeDown {
  &[data-aos="swipe-down"].aos-animate {
    & {
      animation: fadeIn .5s ease-out forwards;
    }

    &:after {
      height: 100%;
    }
  }

  @media (max-width: 560px)  {
    &[data-aos="swipe-down"].aos-animate {
      & {
        animation: none;
      }
    }
  }
}

%industries__button {
  color: $text;
  font-size: 1.125rem;
  padding: 0 1.875rem;
  height: 2.5rem;
  background-color: $darkGray;
  border-radius: 65px;
  margin-bottom: 0.9375rem;

  &.industries__button_active {
    color: $gray;
    background: $accentGrad;
  }
}

%tag {
  color: $text;
  border-radius: 56px;
  display: flex;
  align-items: center;
  height: 1.875rem;
  padding: 0 12px;
  background-color: $gray;

  @media (max-width: 1024px) {
    font-size: 14px;
    line-height: 20px;
    height: 30px;
  }

  //@media (max-width: 475px) {
  //  font-size: 12px;
  //  line-height: 154.5%;
  //  height: 22px;
  //}
}

%darkTag {
  color: $textGray;
  border-radius: 56px;
  display: flex;
  align-items: center;
  height: 1.875rem;
  padding: 0 12px;
  background-color: $pink;

  @media (max-width: 1024px) {
    font-size: 14px;
    line-height: 20px;
    height: 30px;
  }

  @media (max-width: 475px) {
    font-size: 12px;
    line-height: 154.5%;
    height: 22px;
  }
}

%showMoreBtn {
  height: 3.75rem;
  padding: 0 1.25rem 0 1.875rem;
  border: 1px solid $text;
  position: relative;
  min-width: 17.5rem;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //transition: all .3s ease;

  &:after {
    content: '+';
    color: $text;
    font-size: 18px;
    font-weight: 500;
    //transition: all .3s ease;
  }

  &:hover {
    background: $accentGrad;
    color: #fff;
    border-color: transparent;

    &:after {
      color: #fff;
    }
  }

  @media (max-width: 560px) {
    width: 100%;
    height: 60px;
    padding: 0 30px;

    &:hover {
      background: initial;
      color: initial;
      border-color: initial;

      &:after {
        color: initial;
      }
    }
  }
}

%showAllBtn {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 130.5%;
  color: #FA3D33;
  letter-spacing: 0.06em;
  text-decoration-line: underline;
  text-transform: uppercase;
  transition: all .3s ease;
  display: inline-block;
  cursor: pointer;

  &:hover {
    color: darken(#FA3D33, 10%);
  }

  @media (max-width: 1024px) {
    & {
      position: static;
    }

    @media (max-width: 475px) {
      & {
        font-size: 14px;
        text-align: center;
      }
    }
  }
}

.fadeIn {
  animation: in .65s ease-in forwards;
}

.fadeOut {
  animation: out .65s ease-out forwards;
}

%arrow {
  width: 2.8125rem;
  height: 2.8125rem;
  transform: rotate(-1deg);
  transition: opacity .3s ease;
  position: absolute;
  margin: 0;
  display: inline-block;

  svg {
    width: 100%;
    height: 100%;
  }
}

@mixin anim($name, $duration) {
  animation-name: $name;
  animation-duration: $duration;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

%briefBtn {
  background: #EFEFEF;
  border-radius: 5.8125rem;
  height: 3rem;
  font-size: 1.125rem;
  line-height: 1.875rem;
  padding: 0 1.875rem;
  margin-right: 0.75rem;
  margin-bottom: 0.9375rem;
  transition: all .3s ease;

  &:hover {
    background: darken(#EFEFEF, 3%);
  }

  &.brief__button_selected {
    background: $accentGrad;
    color: #fff;
  }
}
