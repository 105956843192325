@import 'src/assets/sass/vars.scss';
@import 'src/assets/sass/mixins';
@import 'src/assets/sass/animations';

.partners {
  display: flex;
  flex-direction: column;
  gap: 8px;
  &__title {
    margin-bottom: 6.5rem;
    font-size: 2.25rem;
    font-weight: 400;
  }
  &-slider{
    background: var(--bg-weak-100, #F6F8FA);
    backdrop-filter: blur(25.6875px);
    height: 72px;
    padding: 0;
    .swiper-wrapper{
      background: #F6F8FA;
    }
    &__slide{
      width:auto!important;
      background: #F5F6F8 !important;
    }
    &__item{
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      height: 68px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin: 0 auto;
      img{
        
      }
    }
  }
}
.marquee{
  width: initial;
  max-width: initial;
  min-width: initial;
}
.child{
  margin-right: 56px;
  width: initial;
}
@media (max-width: 1024px) {
  .partners {
    margin-bottom: 5rem;
    &__item {
      font-size: 4rem;
      &:first-child {
        text-indent: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .marquee, .initial-child-container{
    width: initial !important;
    max-width: initial !important;
    min-width: initial !important;
  }
  .child{
    margin-right: 10px !important;
    width: initial !important;
    min-width: initial !important;
    max-width: initial !important;
  }
  .partners {
    margin-bottom: 0;
    &__item {
      font-size: 2rem;
      display: inline-block;
      &:first-child{
        padding-left: 0;
      }
    }
    &-slider{
      padding: 10px 0;
      height: 48px;
      &__item{
        margin-bottom: 0 !important;
        height: 28px;
        width: initial !important;
        min-width: initial !important;
        max-width: initial !important;
        padding: 0 15px;
        img{
          height: inherit;
          -o-object-fit: contain;
             object-fit: contain;
        }
      }
    }
  }

}

.parthner-torex img{
  margin-top: -2px;
}
.parthner-sawatzky{
  margin-top: 1px;
}
.parthner-rubezh{
  margin-top: 1px;
}
.partners-slider__item{
  img{
    filter: grayscale(100%);
  }
  &:hover{
    img{
      filter: grayscale(0%);
    }
  }
}
@media(max-width: 768px){
  .parthner-torex img{
    margin: 0;
    height: 14px;
  }
  .partners-slider__item img.parthner-rvi{
    height: 18px;
    margin: 0;
  }
  .partners-slider__item img.parthner-rubezh{
    height: 18px;
  }
  .partners-slider__item img.parthner-tavrida{
    height: 24px;
  }
  .partners-slider__item img.parthner-sawatzky{
    height: 18px;
  }
}