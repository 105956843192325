.black-tags{
    margin-bottom: 75px;
    background: #0A0D14 !important;
    padding: 90px 0 0 0;
    &__wrapper{
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: 107px;
        padding: 0 0 52px 0;
    }
    &__title{
        color: #fff;
        max-width: 750px;
    }
    &-itembox{
        &:first-child{
            border-left: 1px dotted #525866;
        }
        display: flex;
        width: 100%;
        justify-content: center;
        padding: 0px 5px;
        border-right: 1px dotted #525866;
        z-index: 1;
    }
    &-container{
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 0;
        position: relative;
        .item {
            border-radius: 50px;
            border: 0.75px solid var(--text-disabled-300, #CDD0D5);
            text-align: center;
            background: transparent;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Arial', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            height: 50px;
            max-width: 178px;
            width: 100%;
            &:hover{
                border: 0.75px solid var(--text-disabled-300, #CDD0D5);
            }
        }
        .item1 {margin-top: 50px;}
        .item2 {margin-top: 100px;}
        .item3 {margin-top: 150px;}
        .item4 {margin-top: 200px;}
        .item5 {margin-top: 250px;}
        .item6 {margin-top: 300px;}
        .item7 {margin-top: 350px;}
    }
}
.line-grid{
    display: none;
}
@media (max-width: 960px) {
    .black-tags{
        padding: 50px 0 0 0;
        &__wrapper{
            gap: 32px;
            padding: 0;
        }
        &-container{
            grid-template-columns: 1fr;
            gap: 37px;
            padding-bottom: 60px;
        }
        &-itembox{
            border: none;
            justify-content: flex-start;
            &:first-child{
                border: none;
            }
            .item{
                margin: 0;
            }
            .item1 {margin-left: 5%; width: 153px;}
            .item2 {margin-left: 15%; width: 179px;}
            .item3 {margin-left: 25%; width: 179px;}
            .item4 {margin-left: 35%; width: 179px;}
            .item5 {margin-left: 45%; width: 179px;}
            .item6 {margin-left: 55%; width: 179px;}
            .item7 {margin-left: 65%; width: 169px;}
        }
    }  
    .line-grid{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        position: absolute;
        width: 100%;
        height: 100%;
        &-item{
            &:first-child{
                border-left: 1px dotted #525866;
            }
            border-right: 1px dotted #525866;
        }
    }  
}
@media(max-width: 768px){
    .line-grid{
        grid-template-columns: repeat(2, 1fr);
        z-index: 0;
        &-item{
            &:first-child{
                border-left: 1px dotted #525866;
            }
            border-right: 1px dotted #525866;
            &:last-child{display: none;}
        }
    }  
}
@media(max-width: 520px){
    .black-tags{
        &-container{
            grid-template-columns: 1fr;
            gap: 37px;
        }
        &-itembox{
            .item1 {margin-left: 5px; width: 153px;}
            .item2 {margin-left: 15px; width: 179px;}
            .item3 {margin-left: 40px; width: 179px;}
            .item4 {margin-left: 65px; width: 179px;}
            .item5 {margin-left: 94px; width: 179px;}
            .item6 {margin-left: 120px; width: 179px;}
            .item7 {margin-left: 158px; width: 169px;}
        }
    }
}