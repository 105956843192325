@import '../../../../../assets/sass/vars';
@import '../../../../../assets/sass/mixins';

.section_contacts {
  background: radial-gradient(91.03% 223.07% at 8.97% 0%, #FA3D33 2.73%, #EE8B52 100%);
  display: flex;
  height: 100vh;
  align-items: flex-end;
  justify-content: flex-start;

  .wrapper {
    padding-top: 10vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: $gray;
    height: 100%;
    justify-content: space-between;
    padding-bottom: 33px;
  }
}

.contacts {
  &__title {
    font-size: 2rem;
    line-height: 130.5%;
    letter-spacing: -0.01em;
    margin-bottom: 0;
    color: #fff;
  }
  
  &__contacts {
    margin: 0;
    gap: 39px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p.body-xl{
      max-width: 537px;
    }
  }

  &__email {
    font-weight: bold;
    width: fit-content;
    margin: 0;
    color: var(--text-white-0, #FFF);
    font-family: 'Arial', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px;
    text-decoration: underline;
  }

  &__address, &__phone {
    font-weight: bold;
    font-size: 2rem;
    line-height: 130.5%;
    letter-spacing: -0.01em;
    opacity: 0.6;
  }

  &__address {
    margin-bottom: 5vh;
  }

  &__phone {
    margin-bottom: 9vh;
  }

  &__brief-btn {
    border: 1.5px solid #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    position: initial;
    height: 80px;
    width: 100%;
    border-radius: 0;
    max-width: 448px;
    margin-top: -8px;
    span{
      color: var(--bg-white-0, #FFF);
      text-align: center;
      position: relative;
      &:after {
          position: absolute;
          bottom: -2px;
          left: 0;
          display: block;
          width: 100%;
          height: 1px;
          background-color: #fff;
          content: "";
          transform: scale(0);
          transition: transform .3s cubic-bezier(0.11, 0.7, 0, 1);
      }
    }
    &:hover span:after {
      transform: scale(1);
    }
  

    svg {
      transition: all .3s ease-out;
    }

    &:hover {
      svg {
        transform: translateX(7px);
      }

    }
  }

  &__soc {
    display: flex;

    &-link {
      transition: all .3s ease;
      opacity: 0.6;
      margin-right: 1.625rem;

      &:hover {
        opacity: 1;
      }
    }
  }
  &-header{
    display: grid;
    grid-template-columns: 1fr 1fr;
    .h3{
      line-height: 93%;
    }
  }
  &-footer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-end;
    &-right{
      display: flex;
      flex-direction: column;
      gap: 38px;
    }
  }
}
.contacts-footer-address{
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.contacts-footer-footer{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  .contacts-copy{
    height: fit-content;
    color: var(--bg-white-0, #FFF);
    text-align: right;
    font-family: 'Arial', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.084px;
  }
  .contacts__soc-link{
    opacity: 1;
    margin: 0;
  }
  .contacts__soc{
    gap: 10px;
  }
}

.footer__bg{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@media(max-width: 1024px){
  .section.section_contacts{
    height: initial;
  }
  .wrapper.wrapper_contacts{
    gap: 75px;
  }
  .contacts-header{
    gap: 51px;
    grid-template-columns: 1fr;
  }
  .contacts__contacts{
    gap: 51px;
    p.body-xl{
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
      letter-spacing: -0.315px;
    }
  }
  .contacts-footer{
    grid-template-columns: 1fr;
    gap: 12px;
  }
  .contacts-footer-right{
    gap: 20px;
  }
  .contacts-footer-footer{
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
}