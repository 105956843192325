.eccomerce{
    background: #fff;
}

@media(max-width: 1024px){
    .eccomerce{
        .title-section__text.body-xl{
            margin-bottom: 40px;
        }
        .razrabotka-slider{
            margin-bottom: 75px;
        }
    }
}