@import 'src/assets/sass/vars';
@import 'src/assets/sass/mixins';
@import 'src/assets/sass/animations';

.structure {
  height: auto;
  overflow: hidden;

  &__info {
    position: relative;
    height: 88rem;
  }

  &__content {
    margin-left: 15%;
    position: absolute;
    top: 8.125rem;
    left: 0;
    width: 50%;
    z-index: 2;

    &-title {
      display: inline-block;
      font-weight: bold;
      font-size: 2.9375rem;
      line-height: 3.125rem;
      margin-bottom: 1.875rem;
    }

    &-text {
      font-size: 1.75rem;
      line-height: 2.5rem;
    }
  }

  &__subtext {
    position: absolute;
    bottom: 25rem;
    left: 53%;
    font-size: 1.125rem;
    line-height: 1.875rem;
    width: 33%;

    p {
      margin-bottom: 15px;
    }
  }

  .orbit__circle {
    position: relative;
    top: 50px;
    right: -15%;
  }

  .orbit__ellipse {
    width: 75rem;
    height: 75rem;
  }

  .orbit__dot-text {
    display: inline-block;
    position: relative;
    font-size: 1.5rem;
    line-height: 1.875rem;
  }

  .orbit__dot-num {
    font-size: 12.5rem !important;
    font-weight: 400;
  }

  #dot1 {
    left: -1%;
    top: 50%;

    .orbit__dot-content {
      top: -15rem;
      flex-direction: row;
      left: 0;
    }

    .orbit__dot-text {
      top: 3rem;
      margin-left: 1rem;
    }
  }

  #dot2 {
    left: 94%;
    top: 69.5%;

    .orbit__dot-content {
      width: 21.875rem;
      top: -15rem;
      left: 0;
    }

    .orbit__dot-text {
      top: 1.5rem;
      margin-left: 1rem;
    }
  }
}

.agency-team {
  height: auto;
  padding-bottom: 10rem;
  background: transparent;
  position: relative;
  margin-top: -18.75rem;
  overflow: hidden;

  .section__header-title {
    width: 100%;
  }

  .wrapper {
    background: transparent;
  }

  &__table {
    display: flex;
    justify-content: space-between;
  }

  .section__header-title:after {
    display: none;
  }

  &__col {
    height: 100%;

    &_text {
      width: 30%;
    }

    &_photos {
      width: 50%;
    }
  }

  &__title {
    font-size: 1.5rem;
    line-height: 125%;
    font-weight: 700;
    letter-spacing: -0.01em;
    width: 90%;
  }

  &__text {
    font-size: 1.125rem;
    line-height: 165%;
    width: 33.75rem;
    margin-bottom: 6.25rem;
    margin-left: 50%;
  }

  &__directors {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.125rem;
  }

  &__director {
    width: 48%;

    &-box {
      width: 100%;
      height: 32.5rem;
    }

    &-pic {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &-name {
      margin-top: 1.4375rem;
      display: block;
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.5rem;
      margin-bottom: 0.3125rem;
    }

    &-position {
      display: block;
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
  }

  &__slider {
    position: relative;
    overflow-x: hidden;
    overflow-y: visible;
    padding-bottom: 2rem;

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }

  &__drag {
    width: calc(100% - 3.75rem);
    position: relative;
    top: 4.375rem;
  }

  &__drag-area {
    position: absolute;
    bottom: 40px;
    left: 0;
    height: 2px;
    width: calc(100% - 3.75rem);
    z-index: 3;
    background: #cecece;

    &:after {
      position: absolute;
      content: '';
      right: -7.5rem;
      top: 0;
      height: 2px;
      width: 7.5rem;
      background: #cecece;
    }
  }

  &__slider-box {
    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 2rem;
      height: 20px;
      width: 100%;
      background: $gray;
      z-index: 1;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: calc(2rem + 10px);
      left: 0;
      width: 100%;
      height: 1px;
      background: $text;
      z-index: 2;
    }
  }

  &__slider-wrapper {
    display: flex;
    overflow-x: auto;
    padding-bottom: 6.25rem;
    position: relative;
  }

  &__slider-btn {
    position: absolute;
    width: 3.75rem;
    bottom: 10px;
    left: 0;
    height: 3.75rem;
    background: purple;
    z-index: 3;
    will-change: transform;
    background: radial-gradient(91.03% 223.07% at 8.97% 0%, #FA3D33 2.73%, #EE8B52 100%);

    &:after {
      content: url('../../../assets/images/icons/slide-arrows.png');
      font-family: $font;
      font-size: 1.5rem;
      line-height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 3.45rem;
      height: 3.45rem;
      top: 0.15rem;
      left: 0.15rem;
      background: $gray;
    }
  }

  &__slide {
    margin-right: 1.25rem;

    &-container {
      height: 25.25rem;
    }

    &-pic {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }

    &-name {
      margin-top: 2.1875rem;
      font-size: 1.125rem;
      line-height: 1.5rem;
      display: block;
      margin-bottom: 0.5rem;
    }

    &-position {
      display: block;
      font-size: 0.9rem;
    }
  }
}

.approach {
  height: auto;
  padding-bottom: 11.25rem;
  
  &__box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__info {
    // display: flex;

    &-text {
      font-size: 1.125rem;
      line-height: 1.875rem;
      width: 100%;
      margin-bottom: 5rem;
    }
  }

  &__list {
    width: 100%;
    margin-left: auto;
  }

  &__text {
    margin-top: 9.375rem;
    font-size: 1.125rem;
    line-height: 1.875rem;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $text;
    padding: 2.5rem 0 1.875rem;

    &-title {
      font-weight: bold;
      font-size: 1.75rem;
      line-height: 2.5rem;
    }

    &-num {
      font-weight: bold;
      font-size: 1.75rem;
      line-height: 2.5rem;
    }
  }
}

@media (max-width: 1700px) {
  .structure .orbit__ellipse {
    width: 70rem;
    height: 70rem;
  }

  .structure .orbit__circle {
    top: 50px;
    right: -10%;
  }

  .structure {
    overflow: visible;
  }

  .agency__info {
    overflow: hidden;
  }
}

@media (max-width: 1400px) {
  .structure .orbit__circle {
    top: -50px;
  }

  .structure__info {
    height: 85rem;
  }

  .structure__content {
    top: 4.125rem;
  }

  .structure #dot1 {
    left: 0;
    top: 60%;
  }

  .structure #dot2 {
    left: 90%;
    top: 78%;
  }
}

@media (max-width: 1200px) {
  .agency-services__service {
    font-size: 48px;
  }

  .structure__info {
    height: 70rem;
  }

  .agency-team {
    margin-top: -7.75rem;
  }

  .structure .orbit__ellipse {
    width: 60rem;
    height: 60rem;
  }

  .structure .orbit__circle {
    top: -150px;
    right: -4%;
  }

  .structure__subtext {
    bottom: 13rem;
  }
}

@media (max-width: 1024px) {
  .agency-team {
    padding-bottom: 70px;
  }

  .agency-team .wrapper {
    width: 100%;
    margin: 0;
  }

  .agency-team__slider .swiper-container {
    padding-left: 3%;
  }

  .agency-team__slide {
    width: 300px;
  }

  .agency-team__slide-name {
    margin-top: 10px;
    font-size: 16px;
  }

  .agency-team__slider .swiper-button-prev,
  .agency-team__slider .swiper-button-next {
    display: block;
    top: auto;
    z-index: 2;
    bottom: 0;
  }

  .agency-team__slider .swiper-button-prev {
    left: auto !important;
    right: 90px;
  }

  .agency-team__slider .swiper-button-next {
    right: 3%;
    left: auto !important;
  }

  .agency-team__slider .swiper-container {
    padding-bottom: 100px;
  }

  .approach__item-title,
  .approach__item-num {
    font-size: 20px;
  }

  .approach__item {
    padding: 30px 0 20px;
  }

  .approach__text {
    font-size: 18px;
    margin-top: 90px;
    width: 60%;
  }

  .approach__info-text {
    font-size: 18px;
    margin-top: 90px;
    width: 60%;
  }

  .approach {
    padding-bottom: 110px;
  }

  .structure__content {
    position: static;
    margin-left: 0;
    margin-top: 50px;
    width: 80%;
  }

  .structure__content-title {
    font-size: 46px;
  }

  .structure__content-text {
    font-size: 28px;
    line-height: 40px;
  }

  .facts-table {
    margin-top: 50px;
  }

  .structure__info {
    height: auto;
  }
  .structure__subtext {
    position: static;
    font-size: 18px;
    margin-left: 28%;
    width: 63%;
  }

  .agency-team {
    margin: 0;
  }

  .structure {
    margin-bottom: 50px;
  }

  .structure {
    .orbit__circle {
      height: 500px;
      top: 0px;
      right: auto;
      left: 15%;
      width: 80%;
    }
  }

  .structure .orbit__ellipse {
    width: 65vw;
    height: 65vw;
  }

  .structure .orbit__dot-num {
    font-size: 100px !important;
  }

  .structure #dot1 .orbit__dot-content {
    top: -210px;
    left: -45px;
  }

  .structure #dot2 .orbit__dot-content {
    top: -205px;
  }

  .structure .orbit__dot-text {
    font-size: 18px;
    line-height: 24px;
  }

  .structure #dot1 .orbit__dot-text,
  .structure #dot2 .orbit__dot-text {
    top: 130px;
    left: -150px;
    margin-left: 0;
  }

  .structure #dot2 .orbit__dot-text {
    margin-top: -10px;
    left: -90px;
    width: 125px;
  }

  .structure #dot1 {
    top: 45%;
    left: -10px;
  }
  .structure #dot2 {
    top: 65%;
    left: auto;
    right: 1%;

    .orbit__dot-num {
      margin-top: -5px;
    }
  }

  .structure__subtext {
    margin-top: -70px;
  }
}

@media (max-width: 780px) {
  .agency-team__slide {
    width: 250px;
  }

  .structure__subtext {
    margin-left: 0;
    width: 70%;
  }

  .approach__item-title {
    display: inline-block;
    width: 80%;
    line-height: 1.5;
  }

  .structure__content {
    width: 100%;
  }

  .structure__content-text {
    width: 80%;
  }

  .approach__list {
    width: 100%;
  }

  .approach__text {
    margin-left: 0;
    width: 70%;
    font-size: 16px;
  }

  .approach__info-text {
    margin-left: 0;
    width: 70%;
    font-size: 16px;
  }

  .approach__info {
    flex-direction: column;
  }

  .structure__content-text {
    line-height: 34px;
  }

  .structure .orbit__dot-text {
    font-size: 16px;
    line-height: 22px;
  }

  .structure .orbit__dot-num {
    font-size: 90px !important;
  }

  .structure #dot1 .orbit__dot-text,
  .structure #dot2 .orbit__dot-text {
    top: 105px;
    left: -80px;
    margin-left: 0;
  }

  .structure #dot1 .orbit__dot-content {
    top: -190px;
    left: -45px;
  }

  .structure #dot2 .orbit__dot-content {
    top: -170px;
  }

  .structure #dot2 .orbit__dot-num {
    margin-top: -20px;
  }

  .structure .orbit__circle {
    height: 450px;
    top: 0;
  }
}

@media (max-width: 670px) {
  .agency-team__slide {
    width: 240px !important;
  }
  .agency-team__slider .swiper{
    padding-bottom: 100px;
    margin-left: 7.5%;
  }
  .structure__content-text {
    font-size: 18px;
  }

  .structure__subtext {
    margin-left: 0;
    width: 80%;
  }
}

@media (max-width: 560px) {
  .approach__text {
    margin-left: 0;
    width: 80%;
    font-size: 14px;
    line-height: 24px;
    margin-top: 50px;
  }

  .approach__info-text {
    margin-left: 0;
    width: 80%;
    font-size: 14px;
    line-height: 24px;
    margin-top: 50px;
  }

  .agency-team__slider .swiper-container {
    padding-left: 4.5%;
  }

  .structure__subtext {
    margin-top: 0;
  }
}

@media (max-width: 475px) {
  .approach__text {
    margin-top: 35px;
  }

  .approach__info-text {
    margin-top: 35px;
    width: 100%;
  }

  .structure__content-text,
  .structure__subtext {
    width: 100%;
  }

  .approach__info {
    margin-bottom: 35px;
  }

  .approach__text {
    width: 100%;
  }

  .agency-team__slide-name {
    font-size: 14px;
    margin-bottom: 3px;
  }

  .agency-team__slider .swiper-container {
    padding-bottom: 90px;
  }

  .agency-team__slider .swiper-button-prev,
  .agency-team__slider .swiper-button-next {
    bottom: 15px;
  }

  .approach__item-title,
  .approach__item-num {
    font-size: 18px;
    line-height: 120%;
  }

  .approach__item {
    padding: 20px 0;
  }

  .structure__content {
    margin-top: 25px;
  }

  .structure__content-text,
  .structure__subtext {
    font-size: 14px;
    line-height: 24px;
  }

  .structure__subtext {
    margin-top: 15px;
  }
}

@media (max-width: 420px) {
  .agency-team__slider .swiper-container {
    padding-left: 7.5%;
  }
}

@media (max-width: 350px) {
}

.approach__box--mg{
  margin-top: 65px;
}
.approach__box{
  &:last-child{
    p{
      margin: 0;
    }
  }
}
.agency__info .swiper-wrapper{
  overflow: visible;
}