@import '../../../assets/sass/vars';
@import '../../../assets/sass/mixins';

.mobile-menu {
  padding-top: 85px;
  position: fixed;
  width: 100%;
  background: $gray;
  top: 0;
  transition: all .4s ease-out;
  left: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 20;
  height: 100dvh;
  overflow-y: auto;
  &__list {
    display: flex;
    width: 94%;
    margin: 0 auto;
    flex-direction: column;
    border-top: 1px  #CECECE solid;
  }

  &__item {
    border-bottom: 1px solid #CECECE;

    &:last-child {
      border-bottom: none;
    }
  }

  &__link {
    line-height: 125%;
    text-align: center;
    letter-spacing: -0.01em;
    color: $text;
    font-weight: 600;
    display: inline-block;
    font-size: 24px;
    width: 100%;
    padding: 15px 0;
    color: var(--bg-white-0, #FFF);
    text-align: center;
    position: relative;
    &:after {
        position: absolute;
        bottom: -2px;
        left: 0;
        display: block;
        width: 100%;
        height: 1px;
        background-color: #fff;
        content: "";
        transform: scale(0);
        transition: transform .3s cubic-bezier(0.11, 0.7, 0, 1);
    }
    &:hover span:after {
        transform: scale(1);
    }
    &-index {
      font-weight: 400;
      font-size: 24px;
      letter-spacing: -0.01em;
      color: $gray;
      opacity: 0.3;
    }
  }

  &__email {
    font-weight: bold;
    font-size: 24px;
    line-height: 128%;
    text-align: center;
    margin-top: 5%;
    margin-bottom: 15px;
    @include textGrad();
  }

  &__phone {
    font-weight: bold;
    font-size: 24px;
    line-height: 128%;
    text-align: center;
    margin-bottom: 10px;
    @include textGrad();
  }

  &__soc {
    margin: 4% auto 0;

    &-icon {
      opacity: 0.3;
      padding: 10px;
      margin-right: 10px;

      svg {
        width: 32px;
        height: 32px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@media (max-width: 670px) {
  .mobile-menu__list {
    width: 91%;
  }

  .mobile-menu {
    //padding-top: 120px;
  }
  .mobile-menu__email {
    margin-top: 30px;
    margin-bottom: 0;
  }

  .mobile-menu__phone {
    margin-top: 30px;
    margin-bottom: 0;
  }

  .mobile-menu__soc {
    margin-top: 40px;
  }
}

@media (max-width: 475px) {
  .mobile-menu__link, .mobile-menu__email, .mobile-menu__phone {
    font-size: 20px;
  }

  .mobile-menu__email {
    margin-top: 30px;
  }

  .mobile-menu__phone {
    margin-top: 20px;
  }

  .mobile-menu__soc {
    margin-top: 20px;
  }

  .mobile-menu__soc-icon svg {
    width: 28px;
    height: 28px;
  }
}

@media (max-width: 420px) {
  .mobile-menu__list {
    width: 85%;

  }
}


@media(max-width: 1024px){
  .menu.mounted{
    width: 100%;
    left: 0;
  } 
  .mobile-menu-bg{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  .mobile-menu__list{
    z-index: 1;
    position: inherit;
    width: 100%;
  }
  .mobile-menu__list{
    border: none;
    margin: 0 auto;
    width: 100%;
    align-items: flex-start;
    padding-left: 3%;
    padding-right: 3%;
    gap: 30px;
    position: initial;
    padding: 0;
  }
  .mobile-menu__item{
    border: none;
  }
  .mobile-menu__link{
    padding: 0;
    color: #fff;
    font-family: 'Arial', sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }
  .mobile-menu__soc{
    margin: 0;
    a{
      margin: 0;
      opacity: 1;
    }
  }
  .mobile-menu{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .mobile-menu-footer{
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 2;
    gap: 32px;
    margin: 0 auto;
  }
  .mobile-menu__soc{
    width: 94%;
    margin: 0 auto;
  }
  .mobile-menu-footer__link{
    height: 75px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(85deg, #FA3D33 -1.61%, #DC824F 98.48%);
    color: #FFF;
    text-align: center;
    font-family: 'Arial', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.084px;
  }
  .menu-container_mobile .mobile-menu__list{
    width: 94%;
  }
}