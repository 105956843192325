.swiper-progress{
    width: 100%;
    height: 2px;
    background: #CDD0D5;
    .swiper-progress-bar{
        background: #0A0D14;
        height: 2px;
    }
}
.razrabotka-slider{
    max-width: 272px;
    margin-left: 0;
    .h3{
        color: #0A0D14;
    }
    .swiper-slide{
        background: #fff;
    }
}
.razrabotka-slider__text{
    margin-top: 10px;
    max-width: 250px;
    color: #000;
    font-family: 'Arial', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
    letter-spacing: -0.143px;
}
.progress-bar{
    width: 100%;
    height: 1.5px;
    background: #E9E9E9;
    max-width: 272px;
    margin-bottom: 28px;
}
.progress-bar__fill{
    background: #0A0D14;
    height: 100%;
}