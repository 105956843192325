@import "../../../assets/sass/mixins";

.notfound {
  margin-bottom: 6.25rem;

  &__title {
    font-weight: bold;
    font-size: 3rem;
    line-height: 3.125rem;

    &-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 5.625rem;
      padding-bottom: 8.125rem;
      @include animatedHorizontalBorder(0, auto, 2px, #C4C4C4);
      @include swipeLeft;
    }
  }
}

@media (max-width: 1024px) {
  .notfound__title {
    font-size: 38px;
  }

  .notfound__title-wrapper {
    padding-top: 70px;
    padding-bottom: 90px;
  }

  .search_404 .search__list {
    margin-top: 50px;
  }
}

@media (max-width: 670px) {
  .notfound__title {
    font-size: 32px;
  }

  .notfound__title-wrapper {
    padding-top: 30px;
    padding-bottom: 70px;
  }
}

@media (max-width: 475px) {
  .notfound__title {
    font-size: 24px;
  }

  .notfound__title-wrapper {
    padding-top: 0;
    padding-bottom: 50px;
  }

  .notfound {
    margin-bottom: 35px;
  }

  .search_404 .search__list {
    margin-top: 15px;
  }
}
