.title-section{
    margin-bottom: 75px;
    padding-top: 120px;
    &__wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
    }
    &__title{
        color:  #0A0D14;
        font-family: 'Arial', sans-serif;
        font-size: 96px;
        font-style: normal;
        font-weight: 400;
        line-height: 94%;
        letter-spacing: -1.92px;
    }
    &__text{
        max-width: 539px;
    }
    &-right{
        padding-top: 27px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 426px;
    }
}

@media(max-width: 1024px){
    .title-section{
        &__title{
            font-size: 48px;
            font-weight: 400;
            line-height: 108%;
            letter-spacing: -0.96px;
        }
        &__wrapper{
            grid-template-columns: 1fr;
            gap: 16px;
        }
        &-right{
            height: initial;
        }
        &__text{
            margin-bottom: 115px;
        }
    }
}