.footer {
  border-top: 1px solid #ffffff;
  height: 11vh;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  align-items: center;

  &__logo {
    &-title {
      text-transform: uppercase;
      font-weight: bold;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      // width: 13.4375rem;
    }

    &-logo {
      width: 100px;
      height: 3.125rem;
      display: flex;
      align-items: center;
      object-fit: contain;
    }

    &-pic {
      width: 100%;
    }
  }

  &__copyright {
    font-size: 0.875rem;
    line-height: 153%;
    opacity: 0.6;
    margin-left: 4rem;
  }

  &__nav {
    width: 600px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .menu-link.active {
    background: none;
    color: #fff !important;

    &:after {
      //display: none;
      background: #fff;
    }
  }
}

@media (max-width: 1200px) {
  .footer__copyright{margin-left: 0;}
  .footer__nav {
    width: 450px;

    .menu-link {
      font-size: 12px;
    }
  }
}

@media (max-width: 1024px) {
  .footer__copyright {
    font-size: 12px;
  }

  .footer__logo-title {
    width: 15%;
  }

  .footer__nav {
    width: 50%;
  }

  .footer__copyright {
    text-align: right;
  }

  .footer__copyright {
    font-size: 14px;
  }

  .footer {
    position: static;
    height: 120px;
  }
}

@media (max-width: 780px) {
  .footer__nav {
    display: none;
  }
  .footer-brand{
    flex-direction: column;
  }
}

@media (max-width: 670px) {
  .footer {
    flex-direction: column;
    padding: 25px 0 30px;
    height: auto;
  }

  .footer__logo-title {
    margin-bottom: 25px;
    width: auto;
  }
}

@media (max-width: 475px) {
  .footer {
    padding: 25px 0 0;
  }
  .footer__logo-title{
    margin-top: 20px;
  }
}
