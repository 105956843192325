@import '../../../assets/sass/mixins';
@import '../../../assets/sass/vars';

.contacts-page {
  overflow: hidden;
  height: 100vh;
  background: #fff;

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .contacts-page__link_email {
    margin-top: 2vh;
  }

  .footer {
    border-top: none;
    position: static;
    &__copyright{
      margin-left: 0;
    }
  }

  &__info {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  &__link {
    margin-top: 4vh;
    display: inline-block;
    color: $textGray;
    font-family: $font;
    font-weight: 700;
    font-size: 1.8125rem;
    opacity: 0.6;

    &_email {
      margin-top: 6vh;
      font-size: 6rem;
      color: $text;
      opacity: 1;
    }
  }

  &__social {
    margin-top: 8vh;
  }

  &__social-link {
    margin-right: 1.625rem;

    &:hover {
      svg {
        opacity: 1;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    svg {
      opacity: 0.6;
      transition: opacity 0.3s;
    }
  }

  &__brief-btn {
    position: absolute;
    top: 47vh;
    right: 5.25rem;
    width: 27.6875rem;
    height: 27.6875rem;
    border: 1px solid $textGray;
    border-radius: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      svg {
        transform: translateX(2rem);
      }
    }

    svg {
      transform: translateX(1.7rem);
      transition: transform 0.3s;

      path {
        fill: $textGray;
      }
    }
  }
}

@media (max-width: 1024px) {
  .contacts-page__link {
    font-size: 18px;
    font-weight: 400;
  }

  .contacts-page__link_email {
    display: inline-block;
    font-size: 47px;
    line-height: 50px;
    margin-bottom: 15px;
    font-weight: bold;
  }
}

@media (max-height: 860px) {
  .contacts-page {
    height: auto;
    min-height: 100vh;
  }

  .contacts-page__brief-btn {
    width: 310px;
    height: 310px;
    bottom: 40px;
    right: 30px;
    top: auto;
  }
}

@media (max-width: 780px) {
  .contacts-page__brief-btn {
    width: 270px;
    height: 270px;
    bottom: 40px;
    right: 30px;
    top: auto;
  }

  .contacts-page .footer {
    height: 70px;
  }

  .contacts-page__info {
    height: 45vh;
  }
}

@media (max-width: 670px) {
  .contacts-page__brief-btn {
    position: static;
    margin: 0 auto;
    width: 400px;
    height: 400px;
    border: 1px solid #cecece;
  }

  .contacts-page .footer {
    margin-top: 45px;
    height: auto;
    padding: 30px 0 30px;
    border-top: 1px solid $textGray;
  }
}

@media (max-width: 475px) {
  .contacts-page__info {
    height: auto;
    margin-top: 30px;
  }

  .contacts-page__link {
    margin: 0;
  }

  .contacts-page__link_email {
    font-weight: bold;
    font-size: 28px;
    line-height: 50px;
    color: #FA3D33;
    order: 1;
  }

  .contacts-page__link_phone {
    font-weight: bold;
    font-size: 18px;
    line-height: 130.5%;
    letter-spacing: -0.01em;
    color: #FA3D33;
    order: 2;
    opacity: 1;
    margin-top: 35px;
  }

  .contacts-page__link_address {
    font-size: 14px;
    line-height: 24px;
    color: $textGray;
    opacity: 1;
    order: 3;
    margin-top: 35px;
  }

  .contacts-page__social {
    margin-top: 35px;
    order: 4;
  }

  .contacts-page__social-link svg {
    width: 32px;
    height: 32px;
  }

  .contacts-page__social-link {
    margin-right: 25px;
  }

  .contacts-page__brief-btn {
    width: 80vw;
    height: 80vw;
    margin-top: 40px;
    font-size: 14px;

    svg {
      width: 25px;
      transform: translate(25px, 1px);
    }
  }

  .contacts-page .footer {
    margin-top: 90px;
  }
}

.contacts-page__inn{
  margin-top: 4vh;
  display: inline-block;
  color: #0A0D14;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 1.2rem;
  opacity: 0.6;
}
.contacts-page__social{
  margin-top: 4vh;
}
@media(max-width: 768px){
  .contacts-page__inn{
    order: 3;
    font-size: 1rem;
    font-weight: 400;
    opacity: 1;
    margin-top: 8px;
  }
}