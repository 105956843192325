@import '../../../assets/sass/vars';
@import '../../../assets/sass/animations';
@import '../../../assets/sass/mixins';

$duration: 15s;
$dim: 1.875rem;

.orbit {
  &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2.25rem;
    font-weight: 400;
  }
  &__circle {
    position: absolute;
    top: 4.125rem;
    right: 0;
    width: 90%;
    height: 110vh;

    &_stopped {
      transform: rotate(-15deg) !important;

      .orbit__dot {
        will-change: transform;
        transform: skew(50deg, 0) rotate(15deg);
        animation: in 0.2s ease;
      }

      .orbit__orbit {
        animation: none;
      }
    }
  }

  &__wrap {
    position: absolute;
    top: 45%;
    left: 45%;
    transform: translate(-50%, -50%);
  }

  &__ellipse {
    will-change: transform;
    display: block;
    width: 39.5rem;
    height: 39.5rem;
    border-radius: 100%;
    border: 1px solid #c4c4c4;
    transform: skew(-50deg, 0);
  }

  &__orbit {
    position: relative;
    width: 100%;
    height: 100%;
    @include anim(rotation, $duration);
  }

  &__dot {
    background: $accentGrad;
    width: $dim;
    height: $dim;
    border-radius: 100%;
    position: absolute;
    transition: all 0.01s linear;
    will-change: transform;
    @include anim(skew, $duration);

    &-content {
      left: 3.625rem;
      top: -6.25rem;
      position: absolute;
      display: flex;
      flex-direction: column;

      svg {
        transition: all 0.05s linear;
      }
    }

    &-num {
      font-weight: 600;
      font-size: 5rem;
      line-height: 128%;
      @include textGrad();
    }

    &-text {
      font-size: 1rem;
      line-height: 1.675rem;
    }
  }
}

#dot1 {
  left: calc(50% - 0.325rem);
  top: -$dim/1.5;
}

#dot2 {
  left: 10px;
  top: calc(2 * 100% / 3);

  .orbit__dot-content {
    flex-direction: row;
  }

  .orbit__dot-num {
    font-size: 6.25rem;
  }

  .orbit__dot-text {
    margin-top: 0.9375rem;
    margin-left: 2.5rem;
  }
}

#dot3 {
  right: 10px;
  top: calc(2 * 100% / 3);

  .orbit__dot-content {
    flex-direction: row;
  }

  .orbit__dot-num {
    font-size: 5rem;
  }

  .orbit__dot-text {
    margin-top: 0.9375rem;
    margin-left: 1.25rem;
  }
}

@media (max-width: 1900px) {
  .orbit__ellipse {
    width: 37rem;
    height: 37rem;
  }
}

@media (max-width: 1700px) {
  .orbit__ellipse {
    width: 33rem;
    height: 33rem;
  }
}

@media (max-width: 1200px) {
  .orbit__circle {
    top: 50px;
    width: 104%;
  }

  #dot1 {
    left: calc(50% - 10px);
    top: -13px;
  }

  #dot2 {
    left: 10px;
    top: calc(2 * 105% / 3);
  }

  #dot3 {
    right: 10px;
    top: calc(2 * 105% / 3);
  }

  .orbit__dot-content {
    left: 50px;
    top: -60px;
  }
}

@media (max-width: 1024px) {
  .orbit__dot {
    width: 25px;
    height: 25px;
  }
}
