.social-section{
    margin-bottom: 75px;
    &__wrapper{
        display: grid;
        grid-template-columns: 2fr 1fr 3fr;
    }
   
    &-item{
        &:nth-child(2){
            text-align: center;
        }
        &:last-child{
            display: flex;
            flex-direction: column;
            gap: 40px;
            max-width: 559px;
            p.body-xl{
                color: #0A0D14;
            }
        }
    }
}

.personbox{
    display: flex;
    align-items: center;
    gap: 17px;
    img{
        width: 64px;
        border-radius: 100%;
        height: 64px;
    }
    p.label-text{
        color:#0A0D14;
    }
    &-text{
        display: flex;
        flex-direction: column;
        gap: 5px;
        span{
            color: var(--text-main-900, #0A0D14);
            font-family: 'Arial', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
            letter-spacing: -0.084px;
        }
    }
}
.telegabox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background: var(--bg-weak-100, #F6F8FA);
    .body-xl{
        max-width: 277px;
        color: #0A0D14;
    }
    &-item{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 10px;
        svg{
            min-width: 30px;
        }
        span{
            color: var(--text-main-900, #0A0D14);
            font-family: Arial;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.084px;
            text-decoration: underline;
        }
    }
}

@media(max-width: 768px){
    .social-section{
        a[href="/brief"]{
            width: 100%;
        }
    }
    .social-section__wrapper{
        display: flex;
    }
    .social-section-item:first-child{
        width: 42%;
    }
    .social-section-item:last-child{
        width: 58%;
        min-width: 190px;
        p.body-xl{
            text-align: left;
            color: #0A0D14;
            max-width: 100%;
            width: 190px;
        }
    }
    .personbox{
        flex-direction: column;
        align-items: flex-start;
        &-text{
            align-items: flex-start;
        }
    }
    .telegabox{
        margin-top: 40px;
        flex-direction: column;
        align-items: flex-start;
        gap: 14px;
        p.body-xl{
            color: var(--text-main-900, #0A0D14);
            font-family: 'Arial', sans-serif;
            font-size: 21px;
            font-style: normal;
            font-weight: 400;
            line-height: 27px;
            letter-spacing: -0.315px;
        }
        &-item{
            flex-direction: row;
            align-items: center;
        }
    }
    .social-section .label-text.black-button{
        margin: 0 auto;
        width: 91%;
        margin-top: 43px;    
    }
}