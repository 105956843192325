@import '../../../assets/sass/vars';
@import '../../../assets/sass/mixins';


.project {
  padding-top: 5.5rem;
  a{
    color: $accent;
    border-bottom: 1px solid $accent;
  }
  a[aria-current="page"]{
    border-bottom: none;
  }
  .title {
    width: 20%;
    font-family: 'Inter', sans-serif;
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: 50px;
    display: inline-block;
  }

  &__html {
    padding-bottom: 144px;
    padding-top: 0;
  }

  &-tagsbox{
    display: flex;
  }

  &__main {
    &-info {
      display: flex;
      flex-wrap: wrap;
      color: $textGray;
      justify-content: space-between;
      margin-bottom: 138px;
      margin-top: 36px;
    }

    &-wrapper {
      width: 50%;
    }

    &-title {
      font-family: 'Inter', sans-serif;
      font-weight: bold;
      color: #0A0D14;
      font-size: 65px;
      line-height: 110%;
      display: inline-block;
      width: 100%;
    }

    &-desc {
      width: 100%;
      font-size: 28px;
      line-height: 42px;
      color: #0A0D14;
      padding-top: 0.625rem;

      &-wrapper {
        width: 49%;
      }
    }

    &-tags, &-types {
      display: flex;
      margin-top: 1.2rem;
      flex-wrap: wrap;
    }

    // background-color: #F8E2FF;
      // color: #0A0D14;
      // padding: 2px 11px;
    &-tag, &-type {
      color: #0A0D14;
      text-align: center;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.14px;
      margin-right: 0.9375rem;
      margin-bottom: 15px;
      padding: 3px 9px;
      background: #F6F8FA;
      margin-right: 0.9375rem;
      margin-bottom: 0.9375rem;
    }

    &-pic {
      width: 100%;
      // height: 41.25rem;
      position: relative;
    }

    &-img {
      width: 100%;
      // height: 100%;
      object-fit: cover;
    }

    &-link {
      position: absolute;
      right: 10%;
      top: 0;
      transform: translateY(-50%);
      width: 12.5rem;
      height: 12.5rem;
      background: $textGray;
      font-size: 0.875rem;
      line-height: 1.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #efefef;
      border-radius: 100%;
    }
  }

  &__task {
    display: flex;
    flex-wrap: wrap;
    padding-top: 144px;
    gap: 144px;

    &-info {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
    }

    &-title {
      display: inline-block;
      width: 50%;
    }

    &-desc {
      display: inline-block;
      width: 45%;
      font-size: 1.125rem;
      line-height: 1.875rem;
    }

    &-img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  &__structure {
    padding-top: 144px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 144px;

    &-facts {
      font-size: 1.125rem;
      line-height: 1.875rem;
      width: 45%;

      &-item {
        margin-bottom: 1.25rem;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: -3.4375rem;
          top: 0.8125rem;
          width: 1.875rem;
          height: 0.0625rem;
          background: $textGray;
        }
      }
    }

    &-list {
      width: 100%;
    }

    &-stats {
      width: 100%;
      margin-top: 4.6875rem;
      display: flex;
      justify-content: space-between;
      border-top: 0.0625rem solid #cecece;
      border-bottom: 0.0625rem solid #cecece;
      padding: 3.75rem 0;

      &Item {
        display: flex;
        flex-direction: column;
        width: 30%;

        &-title {
          font-size: 1.5rem;
          line-height: 1.875rem;
        }

        &-num {
          font-weight: bold;
          display: inline-block;
          font-size: 6.0625rem;
          line-height: 6rem;
          margin: 0.625rem 0 1.25rem -0.3125rem;
        }

        &-text {
          font-size: 1.125rem;
          line-height: 1.875rem;
          width: 50%;
        }
      }
    }

    &-pic {
      width: 100%;
    }

    &-img {
      padding-top: 9.375rem;
      width: 100%;
      height: auto;
    }
  }

  &__design {
    padding-top: 144px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
            flex-direction: column;
    gap: 144px;
    &-info {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &-text {
      margin-bottom: 1.5625rem;
      line-height: 1.875rem;
      font-size: 1.125rem;
    }

    &-facts {
      width: 45%;

      &-item {
        margin-bottom: 1.25rem;
        position: relative;
        line-height: 1.875rem;
        font-size: 1.125rem;

        &:before {
          content: '';
          position: absolute;
          left: -3.4375rem;
          top: 0.8125rem;
          width: 1.875rem;
          height: 0.0625rem;
          background: $textGray;
        }
      }
    }

    &-pic {
      // margin-top: 9.375rem;
      width: 100%;
    }

    &-img {
      width: 100%;
      height: auto;
    }
  }

  &__results {
    padding-top: 6.25rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    &-value {
      font-weight: bold;
      font-size: 6rem;
      line-height: 6rem;
      margin-right: 20%;
    }
  }
}
.services-gallery__services{
  padding: 0;
}
.services-gallery__service{
  height: 100%;
}
.services-gallery__title{
  padding-top: 59px;
  margin-bottom: 104px;
}
.services-gallery__service-title{
  margin-bottom: 26px;
}
@media (max-width: 1024px) {
  .project__main-info {
    margin-top: 4.6875rem;
    margin-bottom: 5.3125rem;
  }
  .project__main-title {
    font-size: 2.9375rem;
    line-height: 3.125rem;
    order: 1;
  }

  .project__main-desc {
    order: 3;
    font-size: 1.125rem;
    line-height: 1.875rem;
    width: 100%;
  }
  .project__main-tags {
    order: 2;
    margin-top: 2.5rem;
    margin-bottom: 3.75rem;
  }

  // .project__main-pic {
  //   height: 25rem;
  // }

  .project__main-link {
    height: 10rem;
    width: 10rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .project__html {
    padding-top: 6rem;
    padding-bottom: 11.25rem;
  }

  .project .title {
    font-size: 2.875rem;
    width: 50%;
  }

  .project__task-desc {
    margin-top: 3.125rem;
    width: 100%;
  }
  .project__task-img {
    // padding-top: 4.375rem;
    padding: 0;
    margin-top: 8rem;
  }

  .project__structure {
    padding-top: 6rem;
  }

  .project__structure-facts {
    margin-top: 3.125rem;
    font-size: 1.125rem;
    line-height: 1.875rem;
    width: 100%;
  }

  .project__structure-facts-item:before {
    left: 0;
  }

  .project__structure-facts-item {
    padding-left: 3.125rem;
  }

  .project__structure-text {
    padding: 0;
  }

  .project__structure-stats {
    margin-top: 6.25rem;
    flex-direction: column;
    width: 100%;
    align-items: center;
    text-align: center;
    border: none;
    padding: 0;
  }

  .project__structure-statsItem {
    width: 100%;
    align-items: center;
    border-top: 0.0625rem solid #cecece;
    padding: 2.1875rem 0 1.5625rem;

    &:last-child {
      border-bottom: 0.0625rem solid #cecece;
    }
  }

  .project__structure-pic {
    padding-top: 0
    // padding-top: 7.5rem;
  }

  .project__design {
    padding-top: 6rem;
  }
  .project__design-img{
    margin-top: 8rem;
  }
  .project__design-facts {
    width: 85%;
    margin-top: 3.125rem;
    font-size: 1.125rem;
    line-height: 1.875rem;
  }

  .project__design-facts-item:before {
    left: 0;
  }

  .project__design-facts-item {
    padding-left: 3.125rem;
  }

  .project__design-pic {
    margin-top: 0;
  }

  .project__results {
    flex-direction: column;
    align-items: flex-start;
  }

  .project__results-value {
    margin-top: 1.875rem;
    font-size: 6rem;
    line-height: 6rem;
  }
}
@media (max-width: 768px){
  section .title.services-gallery__service-title{
    font-size: 21px;
  }
  .services-gallery__title{
    margin-bottom: 0;
  }
}
@media (max-width: 670px) {
  .project__html{
    padding-top: 4.286rem;
    padding-bottom: 4.286rem;
  }
  .project__main-link {
    width: 8.75rem;
    height: 8.75rem;
  }

  .project__main-pic {
    height: 23.125rem;
  }

  .project__main-img {
    height: 100%;
  }

  .project .title {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  .project__structure-text {
    font-size: 14px;
    line-height: 1.7;
  }

  .project__structure-statsItem-num {
    font-size: 4.25rem;
    margin: 0;
  }

  .project__structure-statsItem-text,
  .project__structure-facts-item,
  .project__structure-text {
    font-size: 14px;
    line-height: 1.7;
  }

  .project__results-value, 
  .project__structure, 
  .project__design, 
  .project__structure {
    padding-top: 4.286rem;
  }
  .project__task-img, .project__design-img{
    margin-top: 5.714rem;
  }
  .services-gallery{
    padding-bottom: 121px;
  }
}

@media (max-width: 560px) {
  .project__main-desc-wrapper{
    width: 100%;
  }
  .project__main {
    margin-top: 1.875rem;
  }

  .project__main-wrapper {
    width: 100%;
  }

  .project__main-title {
    width: 80%;
  }

  .project__main-tags {
    margin-top: 1.25rem;
  }
  // .project__task-img{
  //   margin: 0;
  // }
  .project__task-img,
  .project__structure-img {
    height: auto;
  }

  .project__structure-facts,
  .project__task-info {
    // margin-bottom: 80px;
  }
}

@media (max-width: 475px) {
  .project__main-pic {
    // width: 110%;
    position: relative;
    // left: -5%;
    height: 25rem;
  }

  .project__main-link {
    bottom: 0;
    top: auto;
    transform: translate(-50%, 50%);
    left: 50%;
    width: 9.375rem;
    height: 9.375rem;
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  .project__main-desc {
    margin-top: 5rem;
    font-size: 14px;
    line-height: 1.7;
  }

  .project__html {
    padding-top: 4.3rem;
    padding-bottom: 5.625rem;
  }
  .project .title {
    font-size: 18px;
    line-height: 130.5%;
    letter-spacing: -0.01em;
  }

  .project__task-desc {
    margin-top: 0.9375rem;
    font-size: 14px;
    line-height: 1.7;
  }

  .project__task-img {
    // padding-top: 0;
  }

  // .project__structure {
  //   padding-top: 4.0625rem;
  // }

  .project__structure-facts {
    margin-top: 4.375rem;
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
  .project__structure-statsItem-title,
  .project__structure-statsItem-text {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #7f7e82;
  }

  .project__structure-statsItem {
    padding: 0.9375rem 0 1.0625rem;
  }

  .project__structure-statsItem-num {
    font-size: 2.25rem;
    line-height: 3.125rem;
  }

  // .project__structure-pic {
  //   padding-top: 0;
  // }

  // .project__structure-img {
  //   padding-top: 0;
  // }

  // .project__design {
  //   padding-top: 0;
  // }

  .project__design-facts {
    margin-top: 4.375rem;
  }

  .project__design-info {
    margin-bottom: 0;
  }

  .project__design-facts {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  .project__design-pic {
    margin-top: 0;
  }

  .project__design-img {
    padding-top: 0;
    
  }

  .project__results {
    padding-top: 4.375rem;
  }

  .project__results-value {
    margin-top: 1.25rem;
    font-size: 2.25rem;
    line-height: 3.125rem;
  }

  .project__main-tags {
    margin-bottom: 0;
  }

  .project__main-info {
    margin-top: 5.714rem;
    margin-bottom: 4.286rem;
  }

  // .project__task-img {
  //   height: 210px;
  // }

  .project__design-pic .project__task-img {
    height: auto;
  }
  .project__results-value{
    padding-top: 0.9rem;
  }
}

@media (max-width: 768px){
  .menu.mounted{
    width: 91%;
    left: 4.5%;
  }
  .project__main{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 36px;
  }
  .project__main-info{
    margin-top: 0;
    margin-bottom: 0;
  }
  .project__main-pic{
    height: 247px;
  }
  .project__main-desc{
    margin-top: 20px;
  }
  .project__html{
    padding-top: 38px;
    padding-bottom: 0;
  }
  .project__task{
    padding-top: 36px;
    gap: 36px;
  }
  .project__task-img{
    margin-top: 0;
  }
  .project__structure{
    padding-top: 36px;
    gap: 36px;
  }
  .project__structure-facts{
    margin-top: 0;
  }
  .project__design{
    padding-top: 36px;
    gap: 36px;
  }
  .project__design-facts, .project__design-img{
    margin-top: 0;
  }
  .services-gallery{
    padding-bottom: 56px;
  }
  .consul-header, .consul-wrapper{
    gap: 32px;
  }
  .consul-header__title{
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 109%;
  }
  .consul-header__text{
    font-size: 17px;
  }
  .consul__btn{
    width: 100%;
  }
  .consul{
    margin-bottom: 36px;
  }
  .project__task-info{
    gap: 36px;
  }
  .project__design-info{
    gap: 36px;
  }
  .empty-title{
    display: none !important;
  }

}
.review-box{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 144px;
  &__title{
    color: var(--Dark, #0A0D14);
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: 119%;
  }
  .review{
    width: 50%;
  }
  .review__info{
    height: inherit;
    padding: 58px 31px 40px;
    width: 100%;
    min-width: calc(100% - 28px);
    max-width: calc(100% - 28px);
    margin-top: 56px;
    span{
      font-size: 17px;
      font-weight: 400;
      line-height: 175%;
    }
    .review__original{
      width: 238px;
      height: 64px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      border: 1px solid var(--fill-1, #FA3D33);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: inherit;
      letter-spacing: 0.84px;
      text-decoration: underline;
    }
  }
}
@media (max-width: 768px){
  .review-box{
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 48px;
    margin-top: 36px;
    padding: 24px 0 16px 0;
    .review-box__title{
      font-size: 20px;
      line-height: 2.5rem;
    }
    .review{
      width: 100%;
    }
    .review__name{
      font-size: 20px;
    }
    .review__position{
      width: 100%;
      font-size: 17px;
      max-width: 100%;
    }
    .review__info{
      margin-top: 36px;
      padding: 24px 16px;
      min-width: 100%;
      max-width: 100%;
      width: 100%;
      span{
        font-family: 'Inter', sans-serif;
      }
      .review__original{
        font-size: 14px;
        width: 100%;
      }
    }
    .review-box{
      padding-left: 0;
      padding-right: 0;
    }
  } 
}
.project,
.project ~ .services-gallery .services-gallery__service-info,
.project ~ .services-gallery .services-gallery__project,
.project ~ .services-gallery{
  background: #fff !important;
}
.project ~ .services-gallery .services-gallery__title.title,
.project ~ .services-gallery .swiper-slide,
.project ~ .services-gallery .swiper-slide a{
    background: #fff;
  }