@import '../../../assets/sass/vars';
@import '../../../assets/sass/animations';
@import '../../../assets/sass/mixins';

.alert {
  position: fixed;
  z-index: 900;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: in 0.4s ease-out forwards;

  &__content {
    max-width: 37.5rem;
    padding: 4.0625rem 4.6875rem 2.5rem;
    max-height: 37.5rem;
    position: relative;
    text-align: center;
    background: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__close {
    position: absolute;
    padding-top: 1.875rem;
    top: 0;
    right: 0;
    padding-right: 1.875rem;
  }

  &__title {
    color: #0A0D14;
    font-weight: bold;
    font-size: 3rem;
    line-height: 3.125rem;
  }

  &__text {
    color: #0A0D14;
    font-size: 1.125rem;
    line-height: 1.875rem;
    text-align: center;
    margin: 1.5625rem 0 2.8125rem;
  }

  &__icon {
    width: 6.5625rem;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__btn {
    background: $accentGrad;
    height: 4.375rem;
    width: 18.75rem;
    font-size: 1.125rem;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    span{
      color: var(--bg-white-0, #FFF);
      text-align: center;
      position: relative;
      &:after {
          position: absolute;
          bottom: -2px;
          left: 0;
          display: block;
          width: 100%;
          height: 1px;
          background-color: #fff;
          content: "";
          transform: scale(0);
          transition: transform .3s cubic-bezier(0.11, 0.7, 0, 1);
      }
    }
    &:hover span:after {
        transform: scale(1);
    }
  }
}

@media (max-width: 1024px) {
  .alert {
    width: 100%;
  }
  .alert__content{
    width: 70%;
  }
}

@media (max-width: 670px) {
  .alert {
    width: 100%;
    padding: 50px 0 30px;
  }
  .alert__content{
    width: 80%;
  }
  .alert__icon {
    width: 90px;
  }

  .alert__close {
    padding-top: 15px;
    padding-right: 15px;
  }

  .alert__title {
    font-size: 42px;
  }
}

@media (max-width: 475px) {
  .alert {
    width: 100%;
    padding: 50px 0;
  }
  .alert__content{
    width: 94%;
  }

  .alert__text {
    font-size: 14px;
    width: 80%;
    line-height: 24px;
    margin: 15px 0 30px;
  }

  .alert__btn {
    font-size: 14px;
    height: 55px;
  }

  .alert__title {
    font-size: 32px;
    line-height: 40px;
  }
}
