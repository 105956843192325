@import "../../../assets/sass/vars";
@import "../../../assets/sass/mixins";

.brief {
  width: 100%;
  //margin-top: 5.625rem;
  background: #fff;

  //.purple-title__title {
  //  margin-top: 0;
  //}

  &__content {
    width: 54%;
    padding-bottom: 6.25rem;
  }

  &__closeBtn {
    position: absolute;
    top: 0;
    right: 0;
    width: 7.9375rem;
    height: 7.9375rem;
    border-radius: 100%;
    background: #EFEFEF;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    transition: all .3s ease;

    &:hover {
      svg {
        transform: scale(1.2);
      }
    }

    svg {
      height: 2.5rem;
      width: 2.5rem;
      transition: all .3s ease;
    }
  }

  &__desc {
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 1.75rem;
    width: 34.375rem;

    &-phone {
      @include textGrad();
    }
  }

  &__section {
    margin-top: 4.0625rem;

    &-title {
      display: inline-block;
      font-weight: bold;
      font-size: 1.75rem;
      line-height: 2.5rem;
      margin-bottom: 2.5rem;
    }

    &_contacts {
      width: 70%;

      .brief__input-wrapper {
        margin-top: 0.9375rem;
      }
    }
  }

  &__button {
    @extend %briefBtn;
  }

  &__input-wrapper {
    width: 100%;
    position: relative;
    transition: all .3s ease;

    &_file {
      margin-top: 4.0625rem;
      border: 1px solid #0A0D14;
      box-sizing: border-box;
      width: 17.5rem;
      height: 3.75rem;


      &:after {
        content: '+';
        color: $text;
        position: absolute;
        right: 1.25rem;
        top: 1.25rem;
        font-size: 1.125rem;
        font-weight: 500;
        transition: all .3s ease;
      }

      &:hover {
        background: $accentGrad;
        color: #fff;
        border-color: transparent;

        &:after {
          color: #fff;
        }
      }
    }
  }

  &__files {
    margin-top: 1.875rem;

    &-deleteBtn {
      padding-right: 15px;
    }

    &-file {
      margin-bottom: 10px;
    }

    &-title {
      font-size: 18px;

    }
  }

  &__input {
    background: transparent;
    border: none;
    width: 100%;
    border-bottom: 1px solid #CECECE;
    padding: 0.9375rem 0;
    font-size: 1.125rem;
    line-height: 1.875rem;
    transition: all .3s ease;

    &:not(.brief__input_error):focus {
      border-bottom: 1px solid $textGray;
    }

    &_task {
      height: 45px;
      overflow-y: auto;
      word-wrap: break-word;
      resize: none;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    &_error {
      border-bottom: 1px solid #FF0000;
    }

    &_file {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
    }

    &-label {
      display: flex;
      align-items: center;
      left: 0;
      padding-left: 1.875rem;
      position: absolute;
      width: 100%;
      top: 0;
      box-sizing: border-box;
      height: 100%;
      cursor: pointer;
    }
  }

  &__input::-webkit-input-placeholder {
    color: $textGray;
  }

  &__input::-moz-placeholder {
    color: $textGray;
  }

  /* Firefox 19+ */
  &__input:-moz-placeholder {
    color: $textGray;
  }

  /* Firefox 18- */
  &__input:-ms-input-placeholder {
    color: $textGray;
  }

  &__input_task::-webkit-scrollbar {
    display: none;
  }

  &__sendBtn {
    box-sizing: border-box;
    width: 15.9375rem;
    height: 4.375rem;
    background: $accentGrad;
    border-radius: 2px;
    margin-top: 4.0625rem;
    font-size: 1.125rem;
    line-height: 130.5%;
    color: $gray;
    transition: all .3s ease;
    position: relative;
    &::after{
      content: none;
    }
    span{
      position: relative;
      &:after {
        border-radius: 2px;
        font-size: 1.125rem;
        line-height: 130.5%;
        color: $gray;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0;
        top: initial;
        bottom: -2px;
        width: 100%;
        height: 1px;
        background-color: #fff;
        content: "";
        transform: scale(0);
        transition: transform .3s cubic-bezier(0.11, 0.7, 0, 1);
      }
    }
    &:hover {
      span:after {
        transform: scale(1);
      }
    }
  }

  &__policy {
    font-size: 0.875rem;
    line-height: 140%;
    margin-top: 1.875rem;
    width: 60%;
  }
}

.footer_brief {
  position: static;
  border-top: 1px solid #CECECE;

  .footer__logo-logotitle {
    color: $textGray;
  }

  .footer__logo-logo svg {
    circle, path {
      stroke: $textGray;
    }

    path {
      fill: $textGray;
    }
  }
}

@media (max-width: 1024px) {
  .brief__content {
    width: 80%;
  }

  .brief__desc {
    width: 100%;
  }

  .brief__button {
    height: 45px;
    line-height: 30px;
  }
}

@media (max-width: 670px) {
  .brief {
    margin-top: 0;
  }

  .brief__desc {
    font-size: 20px;
  }

  .brief__section_contacts {
    width: 80%;
  }

  .brief__policy {
    width: 70%;
  }
}

@media (max-width: 560px) {
  .brief__content {
    width: 100%;
    margin-top: 60px;
  }

  .brief__section {
    margin-top: 45px;
  }

  .brief__section_contacts {
    width: 100%;
  }

  .brief .purple-title__title {
    padding-left: 2.5%;
  }
}

@media (max-width: 475px) {
  .brief__button {
    height: 48px;
  }

  .brief__desc {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    width: 80%;
  }

  .brief__content {
    padding-bottom: 70px;
  }

  .brief__section {
    margin-top: 60px;
  }

  .brief__section-title {
    font-size: 24px;
    margin-bottom: 30px;
  }

  .brief__button {
    font-size: 14px;
  }

  .brief__input {
    font-size: 14px;
    line-height: 18px;
  }

  .brief__input-wrapper_file {
    height: 60px;
    width: 100%;
  }

  .brief__input-label {
    font-size: 14px;
  }

  .brief__sendBtn {
    height: 70px;
    width: 100%;
    font-size: 18px;
  }

  .brief__policy {
    font-size: 12px;
    width: 90%;
  }
}
