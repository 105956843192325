.history-section{
    margin-bottom: 75px;
}

.history-item{
    border-top: 1px solid var(--text-disabled-300, #CDD0D5);
    display: grid;
    grid-template-columns: 2fr 1fr 3fr;
    gap: 0;
    padding: 29px 3%;
    &__title{
        color: var(--text-main-900, #0A0D14);
        font-family: 'Arial', sans-serif;
        font-size: 21px;
        font-weight: 400;
        line-height: 128%;
        letter-spacing: -0.315px;
        margin-right: 35px;
    }
    &__date{
        color: var(--text-main-900, #0A0D14);
        text-align: center;
        
    }
    &__text{
        color: #0A0D14;
    }
    &:hover{
        background: #0A0D14;
        .history-item__title,
        .history-item__date,
        .history-item__text{
            color: #fff;
        }
    }
}
.history-textbox{
    display: flex;
    flex-direction: column;
    gap: 14px;
}

@media(max-width: 1024px){
    .history-item{
        grid-template-columns: 1fr;
        align-items: flex-start;
        gap: 35px;
        &__title{
            margin: 0;
            text-decoration: underline;
        }
        &__date{
            text-align: left;
        }
        &__text.body-xl{
            font-family: 'Arial', sans-serif;
            font-size: 21px;
            font-style: normal;
            font-weight: 400;
            line-height: 27px;
            letter-spacing: -0.315px;
        }
    }
}