@import '../../../assets/sass/vars';
@import '../../../assets/sass/mixins';
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.swiper-slide {
  background-color: #f9f9f9;
}

.swiper {
  overflow: visible;
}

.swiper-wrapper {
  overflow: hidden;
}

.services-gallery {
  height: auto;
  padding-bottom: 8.75rem;
  overflow: hidden;

  &__title {
    font-weight: 500;
    font-size: 3rem;
    line-height: 3.125rem;
    color: $textGray;
    margin-bottom: 0px;
    padding-top: 4.0625rem;
    // position: absolute;
    // top: 3.125rem;
    // left: 0;
  }

  .section__header-description {
    font-weight: bold;
    font-size: 2rem;
    width: 50%;
    letter-spacing: -0.01em;
    line-height: 5.3125rem;
    position: relative;
    opacity: 0;
    padding-left: 3.0625rem;
  }

  &__table {
    display: flex;
    justify-content: space-between;
    height: 42.5rem;
    border-top: 2px solid #cecece;
    border-bottom: 2px solid #cecece;
  }

  &__services {
    width: 100%;
    position: relative;
    height: 100%;
    // padding: 0 5rem 3.125rem 0;
    padding: 3.125rem 0;
    overflow: visible;
    @include swipeDown;
    & .swiper-slide {
      background-color: #f9f9f9;
    }
  }

  &__projects {
    width: 50%;
    padding: 4.0625rem 0 3.125rem 3.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__project {
    width: 50%;
    position: relative;
    // padding-bottom: 56.25%;
    // overflow: hidden;

    &-pic {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
      transition: all 0.3s ease;
      opacity: 0;
      animation: fade-in 0.3s forwards;
      padding: 4.0625rem 0 3.125rem 3.125rem;
    }
  }

  &__service {
    height: 95%;
    width: 100%;
    display: flex;

    justify-content: center;
    // flex-direction: column;

    &-title {
      display: inline-block;
      font-weight: 500;
      font-size: 1.75rem;
      line-height: 2.5rem;
      color: $textGray;
      margin-bottom: 1.25rem;
    }

    &-desc {
      font-size: 1.125rem;
      line-height: 1.875rem;
      color: $textGray;
      width: 60%;
    }

    &-info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      border-right: 2px solid #cecece;
      width: 50%;
    }
  }

  &__slider {
    height: 100%;

    .swiper-button-prev {
      @extend %arrow;
      bottom: 30px;
      top: auto;
    }

    .swiper-button-next {
      @extend %arrow;
      bottom: 30px;
      top: auto;
      left: auto;
      right: 0;
    }
  }

  .swiper-button-prev {
    position: absolute;
    bottom: 30px;
    top: auto;
    left: auto;
    margin: 0;
    right: 59%;
  }

  .swiper-button-next {
    position: absolute;
    bottom: 30px;
    top: auto;
    margin: 0;
    right: 53%;
  }

  &__link {
    font-size: 1.125rem;
    line-height: 1.875rem;
    color: #FA3D33;
    text-decoration: underline;
    transition: all 0.3s ease;
    position: absolute;
    bottom: 30px;
    left: 0;

    &:hover {
      color: darken(#FA3D33, 10%);
    }
  }
}
@media (max-width: 1024px) {
  .services-gallery {
    padding-bottom: 250px;
  }

  .services__gallery-link {
    text-decoration: underline;
    height: 40px;
    display: block;
    font-size: 18px;
    line-height: 30px;
    color: #FA3D33;
    padding-bottom: 40px;
    border-bottom: 1px solid #CECECE;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .swiper-slide a:first-child{
    margin-bottom: 20px;
  }
  .services-gallery__table {
    height: auto;
    display: block;
    border-top: none;
    // padding-bottom: 50px;
    border-bottom: none;
    position: relative;
  }

  .services-gallery__table .swiper-wrapper{
    padding-bottom: 60px;
    // border-bottom: 1px solid #cecece;
    box-sizing: border-box;
    overflow: hidden !important;
  }

  .services-gallery__title {
    position: static;
    display: inline-block;
    width: 100%;
    line-height: 125%;
    font-weight: 700;
    letter-spacing: -0.01em;
    padding-bottom: 30px;
    border-bottom: 1px solid #cecece;
  }

  .services-gallery__service-title {
    margin-top: 30px;
  }

  .services-gallery__service-desc {
    margin-bottom: 50px;
  }

  .services-gallery__pic-container {
    position: relative;
    // padding-bottom: 56.25%;
  }

  .services-gallery__pic {
    // position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
    height: 290px;
  }
  .services-gallery__slider{
    padding-bottom: 0px;
  }
  .swiper.swiper-fade.swiper-initialized.swiper-horizontal.swiper-autoheight.services-gallery__slider{
    padding-bottom: 70px;
  }
  .services-gallery__slider .swiper-button-prev {
    right: 90px;
    bottom: 5px;
    left: auto !important;
  }

  .services-gallery__slider .swiper-button-next {
    bottom: 5px;
    right: 0;
    left: auto !important;
  }

  // .services-gallery__table {
  //   padding-bottom: 60px;
  // }
}

@media (max-width: 560px) {
  
  .services-gallery__title {
    margin-bottom: 0;
  }
  .services-gallery__service-desc {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 35px;
    width: 100%;
  }

  .services-gallery__slider .swiper-button-prev {
    bottom: 5px;
    right: 70px;
  }

  .services-gallery__slider .swiper-button-next {
    bottom: 5px;
  }

  .services__gallery-link {
    font-size: 13px;
    line-height: 24px;
    margin-bottom: 15px;
  }
}

@media (max-width: 375px) {
  .projects-list__item-title {
    width: 85%;
  }

  .projects-list__item-link {
    width: 15%;
  }
}
