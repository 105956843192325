.h1{
    font-family: 'Arial', sans-serif;
    font-size: 48px;
    font-weight: 400;
    line-height: 108%;
    letter-spacing: -0.96px;
}
.h3{
    font-family: 'Arial', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 125%;
}
.body-xl{
    font-family: 'Arial', sans-serif;
    font-size: 21px;
    font-weight: 400;
    line-height: 128%;
    letter-spacing: -0.315px;
}
.label-text{
    font-family: 'Arial', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 128%;
    letter-spacing: -0.084px;
}
@media(max-width: 768px){
    .h1, .h3{
        font-size: 26px;
        font-weight: 400;
        line-height: 123%;
    }
    .body-xl,
    .label-text{
        font-size: 14px;
        font-weight: 400;
        line-height: 128%;
        letter-spacing: -0.084px;
    }
}
