.success {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5.625rem;

  &__goBack {
    position: absolute;
    top: 2.5rem;
    left: 0;
  }

  &__content {
    z-index: 1;
  }

  &__text {
    font-size: 2.5625rem;
    line-height: 1.22;
    text-align: center;
    color: #efefef;
    display: block;
    margin-top: 1.5625rem;
  }

  &__check {
    display: block;
    margin: auto;
  }

  &__ok {
    margin: 2.5rem auto 0;
    border: 1px solid #efefef;
    border-radius: 2px;
    width: 17.5rem;
    height: 4.6875rem;
    font-size: 0.875rem;
    line-height: 1.43;
    color: #efefef;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #FA3D33;
    }
  }

  &__info {
    margin: 6.25rem auto 0;
    max-width: 80%;
    text-align: center;
  }

  &__social {
    font-size: 1.125rem;
    line-height: 1.5;
    text-align: center;
    color: #efefef;
  }

  &__icons {
    margin-top: 1.25rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
            justify-content: center;
    gap: 2rem;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

.success-page{
  height: 100vh;
  position: relative;
  .success-bg{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }
  .section.header{
    height: 100%;
  }
  .success__ok{
    font-size: 16px;
  }
}