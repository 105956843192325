@import "../../../../../assets/sass/vars";
@import "../../../../../assets/sass/mixins";

.search {
  position: relative;
  background: $gray;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 0 5.625rem 0;
  }

  &__info {
    width: 46%;
    position: relative;
  }

  &__text {
    font-size: 1.125rem;
    line-height: 1.875rem;
    width: 60%;
    margin-top: 4.375rem;
  }

  &__list {
    width: 52%;
  }

  &__item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid $darkGray;
    padding: 2.375rem 0 1.4375rem;

    &-title {
      font-weight: bold;
      font-size: 1.8125rem;
      line-height: 2.5rem;
    }

    &-num {
      font-weight: bold;
      font-size: 1.8125rem;
      line-height: 2.5rem;
    }
  }

  &__showMore {
    position: absolute;
    bottom: 0;
    left: 0;
    @extend %showAllBtn;
  }
}

@media (max-width: 1024px) {
  .search__wrapper {
    flex-direction: column;
    padding-bottom: 100px;
  }

  .search__info {
    width: 100%;
  }

  .search__list {
    width: 100%;
  }

  .search__text {
    margin-top: 50px;
    font-size: 18px;
    margin-bottom: 55px;
  }

  .search__item {
    padding: 20px 0 30px;
  }

  .search__item-title {
    font-size: 30px;
  }

  .search__item-num {
    font-size: 30px;
  }

  .search__showMore {
    position: static;
    margin-top: 60px;
  }
}

@media (max-width: 560px) {
  .search__text {
    width: 85%;
  }

  .search__item-title {
    font-size: 24px;
  }

  .search__item-num {
    font-size: 24px;
  }
  .search__item {
    padding: 15px 0 20px;
  }

}

@media (max-width: 475px) {
  .search__text {
    margin-top: 35px;
    width: 100%;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 25px;
  }

  .search__item-title,  .search__item-num {
    font-size: 18px;
  }

  .search__item {
    padding: 30px 0 17px;
  }
}


