.worked-section{
    margin: 75px 0;
    &__wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    &-left{
        &__title{
            color: #0A0D14;
        }
        min-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &__subtitle{
            max-width: 518px;
            color: #0A0D14;
        }
    }
    &-box{
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    &-right{
        min-height: 600px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        img{
            
            height: 100%;
            width: 100%;
            z-index: 0;
        }
        &__box{
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        &__title{
            display: block;
            position: initial;
            padding: 33px 29px;
            max-width: 466px;
            box-sizing: border-box;
            color: #0A0D14;
        }
    }
    
}

@media(max-width: 1024px){
    .worked-section{
        margin: 40px 0 100px;
        &__wrapper{
            grid-template-columns: 1fr;
            width: 100% !important;
        }
        &-box{
            width: 91%;
            gap: 12px;
            margin: 0 auto;
        }
        &-left{
            &__title{
                max-width: 313px;
            }
            &__subtitle{
                margin-bottom: 59px;
                max-width: 277px;
            }
        }
        &-right{
            height: initial;
            min-height: 370px;
            border-top: 1px solid #DBDBDB;
            background: var(--bg-weak-100, #F6F8FA);
            img{
                object-fit: cover;
                object-position: right;
                object-position: -50%;
            }
            &__title{
                max-width: 161px;
                color: #000;
                font-family: 'Arial', sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 114%;
                letter-spacing: -0.084px;
                padding: 26px 0 0 18px;
            } 
        }
    }
    .partners{
        margin-top: 35px;
    }
    .label-text.parthners-text{
        width: 91%;
        margin: 0 auto;
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.32px;
    }
}