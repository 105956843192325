@media (max-width: 1600px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 1550px) {
  html {
    font-size: 13.5px;
  }
}

@media (max-width: 1500px) {
  html {
    font-size: 13px;
  }
}

@media (max-width: 1450px) {
  html {
    font-size: 12.5px;
  }
}

@media (max-width: 1400px) {
  html {
    font-size: 12px;
  }
}

@media (max-width: 1350px) {
  html {
    font-size: 11.5px;
  }
}

@media (max-width: 1300px) {
  html {
    font-size: 11px;
  }
}

@media (max-width: 1250px) {
  html {
    font-size: 10.5px;
  }
}

@media (max-width: 1200px) {
  html {
    font-size: 10px;
  }
}

@media (max-width: 1024px) {
  html {
    font-size: 10px;
  }
}
