@import "../../../assets/sass/vars";
@import "../../../assets/sass/mixins";

.articles {
  height: auto;
  background: $gray;
  padding-top: 5.625rem;

  .wrapper {
    padding-bottom: 15.625rem;
    z-index: 2;
  }

  &__title {
    font-weight: bold;
    font-size: 4.5rem;
    line-height: 128%;
    color: $accent;
    margin-bottom: 5rem;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: auto;
    width: 75%;
    padding-bottom: 5rem;
    position: relative;
    padding-top: 5rem;
    @include swipeLeft;
    @include animatedHorizontalBorder(0, auto);
  }

  &__article {
    width: 44%;
    margin-bottom: 5rem;


    &-box {
      position: relative;
      height: var(--acticleHeight);
      overflow: hidden;
    }

    &-wrapper {
      width: 100%;
      height: 0;
      overflow: hidden;
      opacity: 1;
      position: absolute;
      bottom: 0;
      left: 0;

      &[data-aos="swipe-down"].aos-animate {
        height: var(--acticleHeight);
      }
    }

    &:nth-child(2n) {
      position: relative;
      top: 3.125rem;
    }

    &-pic {
      object-position: bottom;
      width: auto;
      transition: all .3s ease;
      z-index: 1;
      position: absolute;
      bottom: 0;
      height: var(--acticleHeight);
      left: 0;
      @include hover;
    }

    &-title {
      font-size: 1.125rem;
      line-height: 163%;
      color: $text;
      width: 90%;
      margin-top: 1.25rem;
    }

    &-desc {
      font-size: 1rem;
      line-height: 163%;
      color: $text;
      opacity: 0.8;
      margin-top: 1.25rem;
      width: 60%;
    }
  }

  &__show-more {
    margin-top: 2.5rem;
    border: 1px solid $text;
    width: 17.5rem;
    height: 3.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.25rem;
    margin-left: 25%;

    &:after {
      content: '+';
      display: inline-block;
      color: $text;
      font-size: 0.875rem;
    }
  }

  &__box {
    display: flex;
    justify-content: space-between;
    margin-left: 26%;
    flex-wrap: wrap;
    padding-bottom: 5.75rem;
    border-bottom: 1px solid #0A0D14;
  }

  &__item {
    width: 36.25rem;
    height: 28.75rem;
    box-sizing: border-box;
    margin-bottom: 5rem;

    &:nth-child(2n) {
      margin-top: 5rem;
      margin-bottom: 0rem;
    }
  }

  &__button {
    margin-top: 3.125rem;
    @extend %showMoreBtn;
    margin-left: 22%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      margin-right: 6.875rem;
    }

    margin-bottom: 30.25rem;
  }
}







