@import '../../../assets/sass/vars';
@import '../../../assets/sass/mixins';

.main-page #root .main,
.main-page #root .main section{
  background: #fff ;
}

.main-page #root main section .consul-wrapper{
    background: #F5F6F8;
}
.main-page #root .reviews__slider .swiper-button-prev,
.main-page #root .reviews__slider .swiper-button-next{
    top: 91%;
}
.main-page #root .reviews .swiper-slide,
.main-page #root .team__slider .swiper-slide{
    background: #fff;
}
.main-page #root main section .consul-wrapper{
    grid-gap: 56px;
    gap: 56px;
    padding: 72px 0 96px;
    .consul-header{
        grid-gap: 56px;
        gap: 56px;
        &__title{
            font-size: 65px;
        }
    }
    .consul__btn{
        width: 318px;
    }
}
.main-page #root main section.awardsSection{
    margin-bottom: 144px;
}
.main-page #root main section.reviews{
    margin-bottom: 128px;
}
.main-page #root main section.reviews .team__title{
    margin-bottom: 48px;
}
.main-page .review__position{
    height: 60px;
}
@media (max-width: 560px){
    .main-page #root main section.consul{
        margin-bottom: 72px;
    }
    .main-page #root main section .consul-wrapper{
        width: 100%;
        padding: 40px 16px;
        gap: 32px;
        .consul-header{
            gap: 32px;
        }
        .consul__btn{
            width: 100%;
        }
    }
    .main-page #root main section.reviews{
        margin-bottom: 36px !important;
    }
    .main-page #root main .projects-gallery__project-title{
        font-size: 17px;
        color: #000;
    }
    .main-page #root main section .consul-wrapper .consul-header__title{
        font-family: 'Inter', sans-serif;
        font-size: 32px;
        line-height: 109%;
    }
    .main-page #root main section .consul-main__item{
        width: 70px;
        height: 70px;
    }
    .main-page #root main section.awardsSection{
        margin-bottom: 60px;
    }
    .main-page .review__position{
        height: auto;
    }
}