@import '../../../assets/sass/vars';
@import '../../../assets/sass/mixins';

.projects-gallery {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &-tagsbox{
    display: flex;
  }

  &__project {
    width: 49%;
    margin-bottom: 48px;

    &-pic {
      width: 100%;
      padding-bottom: 56.25%;
      overflow: hidden;
      position: relative;
      &:hover {
        .projects-gallery__project-img {
          transform: scale(1.05);
        }
      }
    }

    &-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 2px;
      transition: all 0.5s ease;
    }

    &-tags, &-types {
      display: flex;
      flex-wrap: wrap;
    }

    &-tag, &-type {
      color: #0A0D14;
      text-align: center;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.14px;
      margin-right: 0.9375rem;
      margin-bottom: 15px;
      padding: 3px 9px;
      background: #F6F8FA;
    }


    &-info {
      padding-top: 20px;

    }

    &-title {
      font-family: 'Arial', sans-serif;
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      line-height: 128%; 
      letter-spacing: -0.315px;
      margin-bottom: 0px;
      color: #0A0D14;
    }

    &-desc {
      font-size: 1.125rem;
      line-height: 1.875rem;
      color: #7f7e82;
      width: 80%;
    }
  }

  &_notClassic {
    .projects-gallery__project {
      &:nth-child(3n + 3) {
        width: 100%;

        .projects-gallery__project-pic {
          height: 48.75rem;
          padding-bottom: 0;
        }

        .projects-gallery__project-desc {
          width: 35%;
        }
      }
    }
  }
}

@media (max-width: 1500px) {
  .projects-gallery_notClassic {
    .projects-gallery__project:nth-child(3n + 3) .projects-gallery__project-desc {
      width: 45%;
    }
  }

  .projects-gallery__project-desc {
    width: 80%;
  }
}

@media (max-width: 1024px) {
  .projects-gallery__project {
    width: 100%;
    margin-bottom: 35px;
  }

  .projects-gallery__project-tags {
    bottom: 15px;
  }

  .projects-gallery__project-title {
    font-size: 24px;
  }
  .projects-gallery__project-desc {
    font-size: 18px;
  }

 
}
@media (max-width: 768px) {
  // .services-gallery__service-title.title{
  //   height: 62px;
  // }
  .projects-gallery__project-tags{
    height: fit-content;
  }
  .projects-gallery__project-tag, .projects-gallery__project-type{
    height: fit-content;
  }
}
@media (max-width: 560px) {
  .projects-gallery__project-title {
    font-size: 24px;
    line-height: 32px;
  }

  .projects-gallery__project-desc {
    font-size: 16px;
    line-height: 26px;
  }
  .projects-gallery__project-tags {
    position: static;
    margin-top: 0;
  }

  .projects-gallery__project-tag {
    margin-right: 13px;
    margin-bottom: 13px;
  }

  .projects-gallery__project-pic {
    position: relative;
    overflow: hidden;
    padding-bottom: 0;
  }

  

  

  .projects-gallery__project-info {
    padding-top: 16px;
  }
}

@media (max-width: 475px) {
  .projects-gallery__project-desc {
    font-size: 14px;
    line-height: 24px;
    width: 100%;
  }
  .projects-gallery__project-title {
    margin-bottom: 16px;
  }

  .projects-gallery__project-pic {
    grid-area: pic;
  }

  .projects-gallery__project-info {
    grid-area: info;
    margin-bottom: 30px;
  }

  .projects-gallery__project-tags {
    bottom: 10px;
  }

  .projects-gallery__project {
    display: grid;
    grid-template-areas:
      'info'
      'pic';
  }

  .projects-gallery {
    padding-bottom: 36px;
  }

  .projects-gallery__project-pic-wrapper {
    height: 100%;
  }
}
@media(max-width: 1024px){
  .projects-gallery_notClassic .projects-gallery__project:nth-child(3n+3) .projects-gallery__project-pic{
    height: 260px;
  }
  .projects-gallery__project-pic {
    position: initial;
    overflow: visible;
    padding-bottom: 0;
    height: 260px;
    &-wrapper{
      position: initial;
      overflow: visible;
      height: 100%;
    }
    img{
      height: 100%;
      width: 100%;
      position: initial;
      object-fit: cover;
    }
  }
}
