@import "../../../assets/sass/vars";
@import "../../../assets/sass/mixins";

.facts-table {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;

    &:nth-child(even) {
      align-items: flex-end;

      .facts-table__item-content {
        display: flex;
        justify-content: flex-end;
      }
    }

    &-content {
      width: 40%;
      display: flex;
    }

    &-num {
      font-weight: 500;
      font-size: 100px;
      line-height: 101%;
      @include textGrad();
      margin-bottom: 15px;
      display: inline-block;
    }

    &-text {
      width: 40%;
      font-size: 18px;
      line-height: 24px;
      margin-left: 15px;
      margin-top: 7px;
    }
  }

  &_main {
    .facts-table__item:nth-child(even) {
      align-items: flex-end;
    }

    .facts-table__item:first-child {
      .facts-table__item-num {
        font-size: 164px;
        font-weight: 500;
      }
    }
  }
}

@media (max-width: 780px) {
  .facts-table__item-content {
    width: 100%;
  }

  .facts-table__item-text {
    width: 25%;
  }
}

@media (max-width: 670px) {
  .facts-table_main {
    .facts-table__item:nth-child(odd) .facts-table__item-content {
      justify-content: flex-start;
    }

    .facts-table__item:nth-child(even) .facts-table__item-content {
      justify-content: flex-end;
    }
  }

}

@media (max-width: 560px) {
  .facts-table__item-num {
    font-size: 96px;
  }

  .facts-table__item-text {
    line-height: 1.875rem;
    width: 30%;
  }

  .facts-table__item-content {
    width: 100%;
  }

  .facts-table__item:nth-child(odd) .facts-table__item-content {
    justify-content: flex-start !important;
  }

  .facts-table__item:nth-child(even) .facts-table__item-content {
    justify-content: flex-end !important;
  }
}

@media (max-width: 475px) {
  .facts-table__item-num {
    margin-bottom: 10px;
  }

  .facts-table__item-text {
    font-size: 14px;
    line-height: 24px;
  }

  .facts-table_main .facts-table__item:first-child .facts-table__item-num {
    font-size: 136px;
    margin-bottom: 0;
    margin-left: -7px;
  }
}

@media (max-width: 420px) {
  .facts-table__item-num {
    margin-bottom: 10px;
  }

  .facts-table_main .facts-table__item:first-child .facts-table__item-num {
    font-size: 70vw;
    margin-bottom: 0;
    margin-left: -7px;
  }

  .facts-table_main .facts-table__item:first-child .facts-table__item-content {
    width: 100%;
  }

  .facts-table_main .facts-table__item:first-child .facts-table__item-text {
    width: 50%;
    margin-left: auto;
  }

  .facts-table__item {
    margin-bottom: 30px;
  }

  .facts-table__item-text {
    width: 30%;
    font-size: 14px;
    line-height: 22px;
    margin-top: 0;
  }

  .facts-table_main  .facts-table__item-text {
    width: 37%;
  }

  .facts-table_main .facts-table__item:first-child .facts-table__item-content {
    margin-bottom: 15px;
  }

  .facts-table_main .facts-table__item:nth-child(even) .facts-table__item-content {
    align-items: flex-end;
  }

  .facts-table_main .facts-table__item:nth-child(odd) .facts-table__item-content {
    align-items: flex-start;
  }

  .facts-table_main .facts-table__item-content {
    width: 100%;
    flex-direction: column;
  }

  .facts-table_main .facts-table__item-text {
    margin-left: 0;
  }
}

@media (max-width: 375px) {
  .facts-table__item-content {
    width: 100%;
  }
}
