.consul{
    margin-bottom: 140px;
    &-wrapper{
        background: #F5F6F8;
        padding: 72px 0 96px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 56px;
    }
    &-header{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 56px;
        &__title{
            text-align: center;
            font-size: 65px;
            font-weight: 700;
            line-height: 110%;
        }
        &__text{
            text-align: center;
            font-size: 17px;
            line-height: 175%;
            max-width: 671px;
        }
    }
    &-main{
        display: flex;
        &__item{
            width: 114px;
            height: 114px;
            border-radius: 100%;
            overflow: hidden;
            img{
                width: 100%;
            }
            &:first-child{
                margin-right: -20px;
                z-index: 3;
            }
            &:nth-child(2){
                z-index: 2;
            }
            &:last-child{
                margin-left: -20px;
            }
        }
    }
    &__btn{
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 15.9375rem;
        height: 4.375rem;
        background: radial-gradient(91.03% 223.07% at 8.97% 0%, #FA3D33 2.73%, #EE8B52 100%);
        border-radius: 2px;
        font-size: 1.125rem;
        line-height: 130.5%;
        color: #f9f9f9;
        transition: all 0.3s ease;
        position: relative;
        text-decoration: underline;
        &:hover:after{
            opacity: 1;
            text-decoration: underline;
        }
        &:after{
            border-radius: 2px;
            font-size: 1.125rem;
            line-height: 130.5%;
            color: #f9f9f9;
            content: "Получить консультацию";
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: #FA3D33;
            opacity: 0;
            transition: all 0.3s ease;
        }
    }
}

@media(max-width: 1540px){
    .consul-header__title{
        font-size: 70px;
        line-height: 89.5px;    
    }
}
@media(max-width: 1125px){
    .consul-header__title{
        font-size: 50px;
        line-height: 65px;    
    }
}
@media(max-width: 810px){
    .consul-main__item {
        width: 90px;
        height: 90px;
    }
    .consul-header__text{
        font-size: 15px;
    }
    .consul-header__title{
        max-width: 360px;
        font-size: 41px;
        line-height: 50px;    
    }
}
@media(max-width: 390px){
    .consul-header__title{
        font-size: 38px;
        line-height: 48px;  
    }
}