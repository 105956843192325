@import '../../../assets/sass/vars';
@import '../../../assets/sass/mixins';

.article {

   &__box {
      display: flex;
      flex-direction: column;
      margin: auto;
      width: 60%;

   }

   &__title {
      width: 75%;
      font-size: 2.875rem;
      font-weight: 700;
      line-height: 2.875rem;
   }

   &__text {

      font-size: 1.125rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.75rem;

      &:last-child {
         margin-top: 2rem;
         margin-bottom: 2.5rem;
      }
   }

   &__textBold {
      margin-top: 5rem;
      color: $text;
      border-left: solid 4px $accent;
      font-size: 1.8125rem;
      font-weight: 700;
      line-height: 2.5625rem;
      padding-left: 1.75rem;
      margin-bottom: 6.25rem;
   }

   &__supImg {
      margin-top: 4.875rem;
      object-fit: cover;
   }

   &__body {
      display: flex;
      justify-content: space-between;
      margin-top: 4.875rem;
   }

   &__paragraph {
      width: 68%;
   }

   &__minTitle {
      color: $text;
      font-size: 1.875rem;
      font-weight: 700;
      line-height: 1.875rem;
   }

   &__more {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 24%;
      align-items: flex-start;
   }

   &__moreTitle {
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 1.625rem;
      color: #C4C4C4;
      margin-bottom: 1.375rem;

   }

   &__moreItems {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
   }

   &__moreItem {
      width: 13.375rem;
      margin-bottom: 2.625rem;
   }

   &__moreItemImg {
      object-fit: cover;
   }

   &__moreItemText {}

   &__subImg {
      object-fit: cover;
   }
}

.social {
   display: flex;
   justify-content: space-between;

   &__box {
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 112px;
      position: sticky;
   }

   &__text {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.0625rem;
      color: #C4C4C4;
      margin-top: 50px;
      transform: rotate(-90deg);
   }

   &__wey {
      display: flex;
      justify-content: space-around;
      position: relative;
   }

   &__item {
      width: 30px;
      margin-bottom: 0.875rem;

      &:hover {
         svg {
            opacity: 1;
         }
      }

      svg {
         transition: opacity .3s;
         opacity: .6;
         width: 100%;
         height: 100%;
      }
   }
}








.articleMore {
   &__title {
      margin-top: 2.625rem;
      margin-bottom: 4.25rem;
      color: $text;
      font-size: 1.8125rem;
      font-weight: 700;
      line-height: 1.8125rem;
   }

   &__box {
      margin: auto;
      width: 100%;
   }

   &__gallery {}

   &__arrows {
      svg {
         fill: red;
         width: 46px;
         height: 46px;

         path {
            fill: red;
            stroke: red;
         }

      }
   }
}








// =============================ENTRY___STYLE=============================


.entry {
   box-sizing: border-box;
   &__img {
       object-fit: cover;
       width: 100%;
   }

   &__title {
       color: $text;
       margin-top: 1.125rem;
       font-size: 1.125rem;
       font-weight: 400;
       line-height: 1.75rem;
   }
}
