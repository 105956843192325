@import "vars";

@keyframes scaleIn{
  100%{
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes scrollText {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes slideDownOut {
  0% {
    bottom: 0;
  }
  100% {
    bottom: -100%;
    visibility: hidden;
  }
}

@keyframes slideDownIn {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0;
  }
}

@keyframes in {
  from {
    opacity: 0;
    visibility: hidden;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes out {
  from {
    opacity: 1;
    visibility: visible;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(40px);
  }

  to {
    opacity: 1;
    transform: translateY(0)
  }
}

@keyframes swipeLeft {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes projectSlideUp {
  from {
    transform: translateY(100%);
    opacity: 0
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeUp {
  from {
    opacity: 0;
    height: 0;
  }
  to {
    opacity: 1;
    height: var(--portfolioPicHeight);
  }
}


@keyframes fadeUpTeam {
  from {
    opacity: 0;
    height: 0;
  }
  to {
    opacity: 1;
    height: var(--teamPicHeight);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes skew {
  0% {
    transform: rotate(0deg) skew(50deg, 0);
  }
  100% {
    transform: rotate(-360deg) skew(50deg, 0);
  }
}

@keyframes burgerFirstLine {
  0% {
    transform: rotate(45deg) scale(0);
  }

  50% {
    transform: rotate(45deg) scale(1.2, 1.2);
  }

  100% {
    transform: rotate(45deg) scale(1, 1);
  }
}

@keyframes burgerSecondLine {
  0% {
    transform: rotate(-45deg) scale(0);
  }

  50% {
    transform: rotate(-45deg) scale(1.2, 1.2);
  }

  100% {
    transform: rotate(-45deg) scale(1, 1);
  }
}


@keyframes fadeUpBtn {
  from {
    bottom: 3.75rem;
    opacity: 0;
  }

  to {
    bottom: 5.625rem;
    opacity: 1;
  }
}

@keyframes fadeDownBtn {
  from {
    bottom: 5.625rem;
    opacity: 1;
  }

  to {
    bottom: 3.75rem;
    opacity: 0;
  }
}
