@import '../../../../../assets/sass/vars';
@import '../../../../../assets/sass/mixins';
@import '../../../../../assets/sass/animations';

.header {
  height: 417px;
  overflow: hidden;
  padding-bottom: 0;
  padding-top: 0;
  &__bg{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:not(.header) {
    position: relative;
    z-index: 2;
  }

  &__info {
    position: relative;
    height: 100%;
  }
  &__list {
    display: flex;
    margin-top: 72px;
    &Item {
      font-size: 1.75rem;
      font-weight: normal;
      line-height: 1.3;
      margin-right: 3rem;
    }
  }
  &-title {
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 13.4375rem;
  }

  &-logo {
    width: 6.25rem;
    display: flex;
    align-items: center;
    object-fit: contain;
  }

  &-logotitle {
    font-weight: 500;
    font-size: 1.125rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  &-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    width: 40%;
  }

  &-item {
    width: auto;
    text-align: center;

    &_cabinet {
      padding: 0 4.375rem 0 1.875rem;

      svg path {
        transition: fill 0.4s ease-out;
      }

      &:hover {
        svg path {
          fill: #fff;
        }
      }
    }
  }

  &-link {
    padding: 5px 0;
    display: block;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 1px;
      background: #fff;
      transition: all 0.3s ease-out;
    }

    &:hover {
      &:after {
        width: 100%;
      }
    }
  }

  &__main-container {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 10vh;
  }

  &__title {
    width: 80%;
    max-width: 1280px;
    font-weight: 700;
    font-size: 6rem;
    line-height: 7.5rem;
    color: #fff;
    position: relative;
    z-index: 2;
    margin-bottom: 0;
    transition: all 0.3s ease;

    &-word {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: -5px;
        left: 0;
        height: 5px;
        width: 100%;
        background-color: #fff;
        transition: all 0.3s ease;
      }

      & > span {
        white-space: pre;
        display: none;
        color: #fff;
        transform: translateY(30%);
        opacity: 0;
        transform-origin: bottom;
        animation: scaleIn 0.3s forwards;
      }
    }
  }

  &__nav {
    display: flex;
    position: relative;
    z-index: 2;
    flex-direction: column;
    margin-right: 24.375rem;

    &-item {
      margin-bottom: 1.25rem;
    }

    &-link {
      padding-bottom: 5px;
      position: relative;
      width: auto;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 1px;
        background: #fff;
        transition: all 0.3s ease-out;
      }

      &:hover {
        &:after {
          width: 100%;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-soc {
      position: absolute;
      z-index: 2;
      right: 0;
      bottom: 7vh;
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-link {
        font-size: 1rem;
        line-height: 163%;
        color: $accent;
        margin-bottom: 1.875rem;

        &:hover {
          svg {
            opacity: 1;
          }
        }

        svg {
          height: 3.0625rem;
          width: 3.0625rem;
          opacity: 0.4;
          transition: all 0.3s ease;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__text {
    font-size: 1.75rem;
    line-height: 2.3125rem;
    color: #ffffff;
    opacity: 0.8;
    position: relative;
    font-weight: 400;
    z-index: 2;
    width: 50%;
  }

  &__arrow {
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 0;
    width: auto;
    height: 70vh;
  }

}

@media (max-width: 1024px) {
  .header__main-container {
    margin-bottom: 5%;
  }

  .header__title {
    line-height: 1.4;
    font-size: 56px;
    width: 80%;
  }

  .header__text {
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
  }

  .header__nav-soc-link svg {
    width: 28px;
    height: 28px;
  }

  .header__nav-soc-link {
    margin-bottom: 15px;
  }

  .header__nav-soc {
    bottom: 5%;
  }
}

@media (max-width: 670px) {
  .header__title {
    font-size: 42px;
  }
  .project {
    padding-top: 9.5rem;
  }
  .header__text {
    font-size: 18px;
    width: 50%;
    font-weight: 400;
  }

  .header__main-container_safari {
    padding-bottom: 45px;
  }
  .header__list {
    flex-wrap: wrap;
    margin-top: 0;
    &Item {
      margin-bottom: 1rem;
      font-size: 14px;
      margin-right: 2rem;
    }
  }
}

@media (max-width: 475px) {
  .header__title {
    font-size: 32px;
    margin-bottom: 16px;
    line-height: 1.3;
    font-weight: 800;
    width: 100%;
  }

  .header__title span:after {
    height: 3px;
    bottom: -3px;
  }

  .header__text {
    font-size: 13px;
    line-height: 16px;
  }

  .header__main-container {
    margin-bottom: 5vh;
  }

  .header__nav-soc {
    bottom: 5vh;
    display: none;
  }

  .header__nav-soc-link svg {
    width: 24px;
    height: 24px;
  }
}

@media (max-width: 475px) {
  .header__text {
    width: 90%;
  }
}

@media (max-width: 375px) {
  .header__title {
    font-size: 26px;
  }
}

@media (max-width: 320px) {
  .header__title {
    font-size: 22px;
  }
}
