@font-face {
  font-family: 'Suisse';
  src: url('../fonts/Coolvetica-Regular.ttf');
}

$text: #0A0D14;
$accent: #EE8B52;
$gray: #f9f9f9;
$darkAccent: #FA3D33;
$darkGray: #efefef;
$textGray: #0A0D14;
$pink: #F8E2FF;

$accentGrad: linear-gradient(85deg, #FA3D33 -1.61%, #DC824F 98.48%);

$font: 'Inter', sans-serif;
$suisse: 'Suisse';
