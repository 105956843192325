.vakancy-section{
    position: relative;
    height: 630px;
    margin-bottom: 75px;
    img{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0;
        left: 0;
    }
    &__wrapper{
        z-index: 1;
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 32px 0;
    }
    &__title{
        color: #fff;
        max-width: 436px;
    }
}

.vakancy-box{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.vakancy-item{
    display: flex;
    flex-direction: column;
    gap: 44px;
    max-width: 524px;
    .body-xl,
    a,
    .label-text{
        color: #fff;
    }
    .label-text{
        max-width: 256px;
    }
}

@media(max-width: 768px){
    .vakancy-section{
        height: 673px;

    }
    .vakancy-box{
        grid-template-columns: 1fr;
    }
    .vakancy-item{
        gap: 20px;
        p.body-xl{
            font-size: 21px;
            font-style: normal;
            font-weight: 400;
            line-height: 27px;
            letter-spacing: -0.315px;
        }
        a{
            text-decoration: underline;
        }
    }
}